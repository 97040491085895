<ng-container *ngIf="dailyHistory.length > 0">
    <section class="ont-state-history-summary" (click)="onSummaryClick($event)">
      <ul>
        <li *ngFor="let thisDay of dailyHistory; index as i;"
            class="ont-state {{thisDay._status}}"
            [attr.data-index]="i"
            title="{{thisDay.intervalStartMoment.format('D MMM')}} {{thisDay.statusName}}">
        </li>
      </ul>
    </section> <!-- ont-state-history-daily -->
  </ng-container>
  
  
