import { Observable, pipe, queue, of, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FdaApiService } from 'src/app/service/fda-api.service';
import {Router} from "@angular/router"
import { FdalibLoggerService } from '@northpower/fda-shared-lib';

@Component({
  selector: 'app-fda-list-faults',
  templateUrl: './fda-list-faults.component.html',
  styleUrls: ['./fda-list-faults.component.scss']
})
export class FdaListFaultsComponent implements OnInit {
 
  public faultQueues: Observable<any[]> = null;
  public allFaultsInProgressQueue: Observable<any> = null;
  public allFaultsInProgress: Observable<any[]> = null;
  private faultDetailsSubs = null;

  constructor(
    private fdaApiService: FdaApiService,
    private router: Router,
    private fdaLog: FdalibLoggerService) { }

  ngOnInit(): void {
    // Call to http://localhost:4400/fda_json/fault_queues and list results

    this.faultQueues = this.fdaApiService.getFaultQueues({});

    this.allFaultsInProgressQueue = this.faultQueues.pipe(
      catchError((err) => { 
        console.log('faultQueues pipe catchError', {err});
        return throwError(err);
      }),
      map((queues) => {
        console.log('allFaultQueues pipe map', {queues});
        // if (!queues || !queues.find) {
        //   return of(null);
        // }
        let result = [];
        if (queues && queues.length > 0)
        {
          result = queues.find((this_queue) => {
            return (this_queue.name == 'In-progress (All)');
          });
        }
        return result;
      })
    );

    this.allFaultsInProgress = this.allFaultsInProgressQueue.pipe(
      catchError((err) => {
        console.log('allFaultsInProgressQueue pipe catchError', {err});
        return throwError(err);
      }),
      switchMap((the_queue) => {
        // if (the_queue == 'FdalibHttpError') // Probably not the solution
        // {
        //   return of(null); // Error was handled by Http Interceptor. Push null into the observable to update view.
        // }
        console.log('allFaultsInProgress pipe map', the_queue);
        return this.fdaApiService.getFaultsInQueue(the_queue.id).pipe(
          map(fault_list => {
            return fault_list.filter(fault => fault.requestType != 'Network Fault');
          })
        );
      }),
    );
  }

  public onFaultClick(fault: any) {
    console.log('onFaultClick', {fault});
    const issue_key = fault.issueKey || '';
    const request_type = fault.requestType || '';
    
    if (request_type == 'Network Fault') {
      console.log('No FSL for Network Faults. Can\'t show FSL details.');
      return;
    }

    // this.cancelFaultDetailsSubscription();

    console.log('issue_key', {issue_key});
    console.log('request_type', {request_type});
    this.faultDetailsSubs = this.fdaApiService.getFaultDetails(issue_key, request_type);
    this.faultDetailsSubs.subscribe((fault_details) => {
      console.log('faultDetailsSubs subscribe', {fault_details});

      if (fault_details.fslInstanceIdentifier)
      {
        this.router.navigate(['/ont_diagnostics', {'fsl_instance_id': fault_details.fslInstanceIdentifier}]);
      }
      else
      {
        this.fdaLog.error('There is no FSL for your fault. We can\'t show ONT diagnostics');
        return;
      }
    });
  }
  
  // private cancelFaultDetailsSubscription() {
  //   if (this.faultDetailsSubs) {
  //     console.log('cancelFaultDetailsSubscription unsubscribing', this.faultDetailsSubs);
  //     // this.faultDetailsSubs.unsubscribe();
  //     this.faultDetailsSubs = null;
  //   }
  // }
}
