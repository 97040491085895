import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as Sentry from '@sentry/browser';

import { FdalibLoggerService } from '@northpower/fda-shared-lib'; 
import { environment } from '../../environments/environment';
import { FdaApiService } from './fda-api.service';

import { FdaVersionInfoService } from './fda-version-info.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class FdaErrorHandlerService implements ErrorHandler { // maybe put this in fdalib library

  public env = environment;

  constructor(private injector: Injector, public fdaApi: FdaApiService, public versionInfo: FdaVersionInfoService) {

    const theVersionInfo = versionInfo.getVersionInfo();

    Sentry.init({
      dsn: 'https://b432e70eabb74b2ca4cbe60c2d1d31a0@o134070.ingest.sentry.io/5546628', // 5546628 is fda project at sentry.io
      environment: this.env.environment,
      release: 'fda@' + theVersionInfo.hash,
    });


  }

  handleError(error) {
    if (error instanceof HttpResponse && error.body == 'FdalibHttpError') {
      console.debug('HttpResponse "FdalibHttpError" handled by FdaErrorHandlerService.handleError');
      return;
    }
    const fdalibLogger = this.injector.get(FdalibLoggerService);
    const location = this.injector.get(LocationStrategy);
    const message = (error.error && error.error.message) ? error.error.message : JSON.stringify(error);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    //
    // Use the fdalib Logger to log this error which was thrown somewhere in the angular app.
    // Mick Temp: Get a stack trace
    //
    fdalibLogger.error_no_toast('FdalibErrorHandler: ' + message, url); // logs to server and to Sentry

    // Mick Temp: Do this! Report error to sentry app in context of current user (if any)
    // Sentry.withScope((scope) => {
    //   scope.setUser({
    //     'username': this.b2bApi.loggedInUserName,
    //     'email': this.b2bApi.loggedInEmailAddress,
    //   });
    //   // scope.setLevel('error');
    //   Sentry.captureException(error.originalError || error);
    // });

    throw error;
  }
  
}