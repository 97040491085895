import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ONTStatusClass } from '../../class/fdalib-ont-status-class';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';

@Component({
  selector: 'fdalib-ont-daily-history',
  templateUrl: './fdalib-ont-daily-history.component.html',
  styleUrls: ['./fdalib-ont-daily-history.component.scss']
})
export class FdalibOntDailyHistoryComponent implements OnInit {

  @Input() dailyHistory: ONTStatusClass[];
  @Output() selectedDay: EventEmitter<ONTStatusClass> = new EventEmitter<ONTStatusClass>();

  public theSelectedDay: ONTStatusClass = null;

  constructor(private fdaLog: FdalibLoggerService) { }

  ngOnInit() {
  }

  public onDayClick(day: ONTStatusClass)
  {
    this.fdaLog.debug('onDayClick got day', day);
    this.theSelectedDay = day;
    this.selectedDay.next(day);
  }

}
