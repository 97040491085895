import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';

@Component({
  selector: 'fdalib-port-configuration-information',
  templateUrl: './fdalib-port-configuration-information.component.html',
  styleUrls: ['./fdalib-port-configuration-information.component.scss']
})
export class FdalibPortConfigurationInformationComponent implements OnInit {

  @Input() portConfigDetails: Observable<Object>;


  constructor(private fdaLog: FdalibLoggerService) { }

  ngOnInit() {
  }

  onLogCircuitFaultClick(fibreServiceId, fibreCircuitId)
  {
    this.fdaLog.debug('onLogCircuitFaultClick', fibreServiceId, fibreCircuitId);
  }
}
