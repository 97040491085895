import { Component, OnInit, TemplateRef } from '@angular/core';
import {FdalibToastService} from '../../service/fdalib-toast.service';

@Component({
  selector: 'fdalib-toast-container',
  templateUrl: './fdalib-toast-container.component.html',
  styleUrls: ['./fdalib-toast-container.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class FdalibToastContainerComponent implements OnInit {

  constructor(public fdalibToastService: FdalibToastService) {}

  ngOnInit() {
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  public hide(toast) {
    this.fdalibToastService.remove(toast);
  }
}

