import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';

// import { FAPIService } from './service/fapi.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

// import { FdalibLoggerService } from './fdalib-logger.service';
// import { NgbDatepickerConfig, NgbDateParserFormatter, NgbDateAdapter, NgbDate, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
// import { NgbDateNorthpowerParserFormatter, NgbDateNorthpowerAdapter } from "./ngb-date-northpower"
// import * as moment from 'moment-timezone';
// import { VersionInfoService } from './version-info.service';

@Component({
  selector: 'app-root',
  providers: [
    // {provide: NgbDateParserFormatter, useClass: NgbDateNorthpowerParserFormatter},
    // {provide: NgbDateAdapter, useClass: NgbDateNorthpowerAdapter},
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'fda';
  public env = environment;
  // public versionHash: string;


  constructor(private location: Location, private oidcSecurityService: OidcSecurityService) {
    // this.versionHash = 'HASHHASHHASH';
  }

  ngOnInit() {

    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
      console.log('app authenticated', isAuthenticated);
      const at = this.oidcSecurityService.getToken();
    });
  }



  
}