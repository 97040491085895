import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, pipe, empty, of, throwError } from 'rxjs';
import { map, shareReplay, catchError, switchMapTo, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import { FdalibLoggerService, ONTStatusJson, ONTFaultJson } from '@northpower/fda-shared-lib';
import { ONTHistoricAlarmJson } from 'projects/shared-lib/src';

@Injectable({
  providedIn: 'root'
})
export class FdaApiService {

  public env = environment;

  private getONTDiagnosticsRefreshSubject: BehaviorSubject<string> = null;
  private getONTStateCurrentRefreshSubject: BehaviorSubject<string> = null;
  private getONTStateHistoryDailyRefreshSubject: BehaviorSubject<string> = null;

  constructor(private httpClient: HttpClient, private fdaLog: FdalibLoggerService) { }

  //
  // Faults
  //
  public getFaultQueues(getParams: any): Observable<any[]> {
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/fault_queues', [], 'getFaultQueues', true);
  }

  public getFaultsInQueue(queue_id: string): Observable<any[]> {
    const getParams = {
      queue_id: queue_id,
    };
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/faults_in_queue/' + queue_id, [], 'getFaultsInQueue', true);
  }

  public getFaultDetails(issue_key: string, request_type: string): Observable<any[]> {
    const getParams = {
      issue_key,
      request_type,
    };
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/fault_details', [], 'getFaultDetails', true);
  }

  public getONTFaultHistory(fslInstanceId: string): Observable<ONTFaultJson[]> {
    const getParams = {
      fslInstanceId,
    };
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/faults_by_ont', [], 'getONTFaultHistory', true).pipe(
      map((faultList: any) => {
        // Expect faultList e.g. like this:
        // faults: [
        //   {
        //     b2bFaultRefNum: '111812',
        //     accessSeekerFaultRef: 'sla-test-mick',
        //     FSLID: '00233628WLFC651',
        //     serviceIdentifier: 'FSID00035238',
        //     circuitIdentifier: 'FCID00138775 (Data)',
        //     faultType: 'Intermittent Connection',
        //     status: 'Escalated',
        //     isInProgress: true,
        //     shortAddress: '1 Riverside Drive\nRiverside\nWhangarei',
        //     raisedAt: '2020-10-06T13:57:00+13:00',
        //     occurredAt: '2020-10-06T13:56:00+13:00',
        //     restoredAt: null,
        //     description: 'SLA Test by Mick changed',
        //     emergencyFault: false,
        //     emergencyFaultReason: null,
        //     criticalResponseRequested: false,
        //     retailerContactName: 'jam 1.2.0',
        //     retailerContactPhone: '1234',
        //     retailerContactEmail: 'ian@jamdigital.co.nz',
        //     customerName: 'Mick Buckley changed',
        //     customerPhone1: '09434028899',
        //     customerPhone2: '09434028899',
        //     siteContactName: 'Mick Buckley changgeeeeddd',
        //     siteContactPhone: '0224340288999',
        //     selfIsolation: false,
        //     address: null,
        //     restorationNoteSymptom: 'close with sub find',
        //     restorationNoteProblem: "Customer's Contractor Damage",
        //     restorationNoteAction: 'close with sub do',
        //     onSiteAt: '2021-02-02T00:30:00+13:00'
        //
        //     issueKey: 'FFT-123'
        //     summary: 'FFT-123 8 Acacia Drive Raumanga Whanagrei'
        //     l1Contractor: 'FFT-123'
        //     fmcUrl: 'FFT-123'
        //     cmcUrl: 'FFT-123'
        //
        //      ... and much more ...
        //
        //   },
        //   ...
        // ]
        //
        // console.log('getONTFaultHistory pipe map', {faultList});
        let result: ONTFaultJson[] = [];
        if (faultList && faultList.length > 0) {
          result = faultList.map((thisFault) => {
            const mappedFault: ONTFaultJson = {
              'faultType': thisFault.faultType,
              'b2bReference': thisFault.b2bFaultRefNum,
              'rspReference': thisFault.accessSeekerFaultRef,
              'faultStatus': thisFault.status,
              'faultRaisedDate': thisFault.raisedAt,
              'serviceRestoredDate': thisFault.restoredAt,
              'faultDescription': thisFault.description,
              'faultSummary': thisFault.summary,
              'faultSymptom': thisFault.restorationNoteSymptom,
              'faultCause': thisFault.restorationNoteProblem,
              'faultAction': thisFault.restorationNoteAction,
              'shortAddress': thisFault.shortAddress,
              'issueKey': thisFault.issueKey,
              'l1Contractor': thisFault.l1Contractor,
              'fmcUrl': thisFault.fmcUrl,
              'cmcUrl': thisFault.cmcUrl,
              'isInProgress': thisFault.isInProgress,
            };
            return mappedFault;
          });
        }
        // console.log('getONTFaultHistory pipe map returns', {result});
        return result;
      }),
    );
  }

  public getHistoricAlarms(fslInstanceId: string): Observable<ONTHistoricAlarmJson[]> {
    const getParams = {
      fslInstanceId,
      // fake: true, 
    };
    const result = this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/historic_alarms', ['alarms'], 'getHistoricAlarms', false);
    // this.fdaLog.debug('getHistoricAlarms', {result});
    return result;
  }

  //
  // Query Services
  //
  public getQueryServices(getParams: any) { // Expects: fsl, fsid, fsl_instance
    // this.fdaLog.debug('FDAAPIService getQueryServices');
    const result = this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/query_services', [], 'getQueryServices', true);
    return result;
  }

  //
  // ONT Diagnostics
  //
  public getONTDiagnosticsWithRefresh(getParams: any): Observable<any>
  {
    return this.getObservableWithRefresh(getParams, this.getONTDiagnosticsRefreshSubject, this.getONTDiagnostics.bind(this));
  }
  public getONTDiagnostics(getParams: any): Observable<any> {
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/ont_diagnostics', [], 'getONTDiagnostics', false);
  }
  public refreshONTDiagnostics()
  {
    this.refreshByRefreshSubject(this.getONTDiagnosticsRefreshSubject, 'refresh-ont-diagnostics');
  }

  //
  // NetAdmin ONT Lookup
  //
  public getAddressByAddressIdentity(fsl_instance_id: string): Observable<any>
  {
    const getParams = {
      'fslInstanceId': fsl_instance_id, 
    };
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/get_address_by_address_identity', ['Address'], 'getAddressByAddressIdentity', false);
  }
  
  //
  // ONT State
  //
  public getONTStateCurrentWithRefresh(getParams: any): Observable<ONTStatusJson>
  {
    return this.getObservableWithRefresh(getParams, this.getONTStateCurrentRefreshSubject, this.getONTStateCurrent.bind(this));
  }
  public getONTStateCurrent(getParams: any): Observable<ONTStatusJson>
  {
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/ont_state/current', [], 'getONTStateCurrent', false);
  }
  public refreshONTStateCurrent(): void
  {
    this.refreshByRefreshSubject(this.getONTStateCurrentRefreshSubject, 'refresh-ont-state-current');
  }

  public getONTStateHistoryDailyWithRefresh(getParams: any): Observable<ONTStatusJson[]>
  {
    return this.getObservableWithRefresh(getParams, this.getONTStateHistoryDailyRefreshSubject, this.getONTStateHistoryDaily.bind(this));
  }
  public getONTStateHistoryDaily(getParams: any): Observable<ONTStatusJson[]>
  {
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/ont_state/history_daily', [], 'getONTStateHistoryDaily', false);
  }
  public refreshONTStateHistoryDaily()
  {
    this.refreshByRefreshSubject(this.getONTStateHistoryDailyRefreshSubject, 'refresh-ont-state-history-daily');
  }

  public getONTStateHistoryHourly(getParams: any): Observable<ONTStatusJson[]>
  {
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/ont_state/history_hourly', [], 'getONTStateHistoryHourly', false);
  }

  public getONTStateHistoryAllInHour(getParams: any): Observable<ONTStatusJson[]>
  {
    return this.getEndpointThenMapResponse(getParams, environment.fdaapi_server + '/fda_json/ont_state/history_all_in_hour', [], 'getONTStateHistoryAllInHour', false);
  }

  //
  // Non-api Functions
  //
  private getObservableWithRefresh(getParams: any, refreshSubject: BehaviorSubject<string>, observableFunction: (gp: any) => Observable<any>): Observable<any>
  {
    if (!refreshSubject)
    {
      refreshSubject = new BehaviorSubject<string>('');
    }
    return refreshSubject.pipe(
      switchMapTo(observableFunction(getParams)),
      shareReplay(1),
    );
  }

  private getEndpointThenMapResponse(getParams: any, url: string, payloadFieldArray: string[], caller: string, isShareReplay: boolean): Observable<any> {
    this.fdaLog.debug('FDAAPIService ' + caller + ' getEndpointThenMapResponse started. Using url ', url);
    const shareReplayCount = isShareReplay ? 1 : 0; // count of zero is same as not having share replay
    return this.httpClient
      .get<any>(url, {params: getParams})
      .pipe(
        map(fdaResponse => {
            return this.mapFDAResponse(fdaResponse, payloadFieldArray, caller);
          },
        ),
        shareReplay(shareReplayCount), // shareReplay(0) is a no-op
      );
  }

  private refreshByRefreshSubject(refeshSubject: BehaviorSubject<string>, refreshString: string)
  {
    if (!this.getONTStateCurrentRefreshSubject)
    {
      this.fdaLog.debug('Warning: could not refresh-ont-state-current');
      return;
    }
    this.getONTStateCurrentRefreshSubject.next('refresh-ont-state-current');
  }

  private mapFDAResponse(fdaResponse, payloadFieldArray: Array<string>, caller: string)
  {
    //
    // Handle error response from fdaapi endpoint and extract payload from response object.
    // You can pass an array of payload fields and this function will descend the fdaResponse object field by field.
    //
    // console.log('FDAAPIService::' + caller + ' mapFDAResponse started with fdaResponse: ', fdaResponse);
    if (fdaResponse == 'FdalibHttpError')
    {
      this.fdaLog.debug(caller + ' got FdalibHttpError from server'); // Error was handled in HttpInterceptor
      return []; // Mick Temp: Test this. I think this code is redundant now that HttpInterceptor throws its errors.
    }
    else if (!fdaResponse)
    {
      this.fdaLog.error(caller + ' got no response from server');
      return [];
    }
    else if (fdaResponse.Errors)
    {
      this.fdaLog.error('Problem with server response', caller + ': ' + fdaResponse.Errors.errorDescription);
      return [];
    }
    let payload = fdaResponse;
    for (const thisField of payloadFieldArray)
    {
      if (!payload[thisField])
      {
        // console.log('FDAAPIService::' + caller + ' no descent BY ' + thisField);
        // We could not descend the fdaResponse object as requested.
        this.fdaLog.error('Some data was missing in server response', caller + ': ' + JSON.stringify(payloadFieldArray));
        return [];
      }
      // console.log('FDAAPIService::' + caller + ' descending by ' + thisField);
      payload = payload[thisField];
    }
    return payload ? payload : [];
  }


}









