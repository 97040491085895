import { Component, OnInit } from '@angular/core';
// import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { delay, map, tap, catchError } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'fdalib-fdalib-user-profile',
  templateUrl: './fdalib-user-profile.component.html',
  styleUrls: ['./fdalib-user-profile.component.scss']
})
export class FdalibUserProfileComponent implements OnInit {

  public is_logged_in = false;
  public plural_roles: string = 's';
  public userData: Observable<any> = this.oidcSecurityService.userData$.pipe(
    tap((userData) => {
      console.log('FdalibUserProfileComponent got userData', userData);
      if (userData.app_roles && userData.app_roles.length == 1)
      {
        this.plural_roles = '';
      }
    })
  );

  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthorized) => {
      this.is_logged_in = isAuthorized;
    });
  }

}

