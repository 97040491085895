<!-- <h4>Diagnostics for ONT {{fsl_instance}}</h4> -->

<ng-container *ngIf="(queriedServices | async) as theQueriedServices; else loading" id="queried-services">

  <ng-container *ngIf="theQueriedServices != 'NoData'; else servicesLoadingFailed">
  
      <section id="diagnostic-snapshot-panel">
  
        <div class="container container-header">
          <header class="row">
            <section class="col-sm-3">
              <label>FSL Instance ID</label>
              <h1>{{theQueriedServices.ONTServices?.length > 0 ? theQueriedServices.ONTServices[0]?.fslInstanceIdentifier : ''}}</h1>
            </section>
            <section class="col-sm-5">
              <label>Address</label>
              <!-- <button type="button" class="btn btn-light float-right">More Customer Information</button> -->
              <ng-container *ngIf="servicesAddress | async as theServicesAddress">
                <h2 [innerHtml]="theServicesAddress | nl2br | safe: 'html'"></h2>
              </ng-container>
            </section>
            <section class="col-sm-4">
              <label>Last Provisioning Change</label>
              <h2>{{theQueriedServices.mostRecentChange | date :'d MMMM yyyy'}}</h2>
            </section>
          </header> <!-- row -->
        </div> <!-- container -->
  
        <div class="container">
          <div class="row">
            <section class="col-sm-3">
              <label>ONT Model</label>
              <ng-container *ngIf="(ontAddress | async) as theOntAddress">
                <h1 class="pt-2 pb-2">{{theOntAddress.ONTMakeModel ? theOntAddress.ONTMakeModel : ''}}</h1>
                <ng-container *ngIf="theOntAddress.ONTMakeModel">
                  <a [href]="'/ont_model_details?model=' + theOntAddress.ONTMakeModel.toLowerCase().replace(' ', '-')">ONT Model Details</a>
                </ng-container>
              </ng-container>
            </section>
            <section class="col-sm-5">
              <ng-container *ngIf="(ontStateCurrent | async) as theONTStateCurrent; else noOntStatus" id="ont-state-current">
                <fdalib-ont-status-badge [status]="theONTStateCurrent"></fdalib-ont-status-badge>
              </ng-container>
              <ng-container *ngIf="(ontStateHistoryDaily | async) as historyDailyForSummary">
                <fdalib-ont-history-summary [dailyHistory]="historyDailyForSummary" (summaryClick)="scrollToTarget(ontStateHistoryAnchor)"></fdalib-ont-history-summary>
              </ng-container>
              <ng-template #noOntStatus>
                <p class="mt-2">
                  <fdalib-dot-dot-dot colorStyle="regular" size="regular"></fdalib-dot-dot-dot>
                </p>
              </ng-template>
             </section>
            <section class="col-sm-4">

              <ng-container *ngIf="(ontFaultHistory | async) as theONTFaultHistory">
                <ng-container *ngIf="inProgressFaultCount > 0">
                  <h2 class="mb-4 xx-text-right">
                    <a class="faux-anchor" (click)="scrollToTarget(faultHistoryAnchor)">
                      {{inProgressFaultCount}} Fault{{inProgressFaultCount == 1 ? '' : 's'}} In Progress
                      <img class="fault-flag" src="assets/img/fault-in-progress-flag.svg">
                    </a>
                  </h2>
                </ng-container>
              </ng-container>
 

              <ng-container *ngIf="(ontDiagnostics | async) as theONTDiagnostics; else extraDiagLoading">
                <h2 class="mb-2">
                  ONT Details
                  <img class="ont-icon ml-2" src="assets/img/ont-icon.svg" />
                </h2>
                <ng-container *ngIf="theONTDiagnostics.diagnosticsResult?.full_diagnostics && theONTDiagnostics.diagnosticsResult?.simple_diagnostics">
                  <section class="diagnostic-points">
                    <section class="diagnostic-point fibre-icon-container" title="Light coming from ONT measured at ONT">
                      <span class="d-label">
                        <img class="fibre-icon" src="assets/img/fibre-icon.svg" />
                      </span>
                      <span class="d-point">{{theONTDiagnostics.diagnosticsResult.simple_diagnostics.ont_light_level?.trim() != 'dBm' ? theONTDiagnostics.diagnosticsResult.simple_diagnostics.ont_light_level : ''}}</span>
                    </section>
                    <section class="diagnostic-point">
                      <span class="d-label">Id: </span>
                      <span class="d-point">{{theONTDiagnostics.diagnosticsResult.full_diagnostics.ont}}</span>
                    </section>
                    <section class="diagnostic-point">
                      <span class="d-label">Serial Number: </span>
                      <span class="d-point">{{theONTDiagnostics.diagnosticsResult.full_diagnostics.vendor ? theONTDiagnostics.diagnosticsResult.full_diagnostics.vendor + theONTDiagnostics.diagnosticsResult.full_diagnostics.serial : ''}}</span>
                    </section>
                    <section class="diagnostic-point">
                      <span class="d-label">Uptime: </span>
                      <span class="d-point">{{theONTDiagnostics.diagnosticsResult.full_diagnostics.uptime}}</span>
                    </section>
                  </section>
                </ng-container>


                <h2 class="mt-4 mb-2">
                  OLT Details
                  <img class="olt-icon ml-2" src="assets/img/olt-icon.svg" />
                </h2>
                <ng-container *ngIf="(ontDiagnostics | async) as theONTDiagnostics">
                  <ng-container *ngIf="theONTDiagnostics.diagnosticsResult?.full_diagnostics && theONTDiagnostics.diagnosticsResult?.simple_diagnostics">
                    <section class="diagnostic-points">
                      <section class="diagnostic-point fibre-icon-container"  title="Light coming from OLT measured at ONT">
                        <span class="d-label">
                          <img class="fibre-icon" src="assets/img/fibre-icon.svg" />
                          <!-- <img class="fibre-icon" src="assets/img/fibre-icon.png" srcset="assets/img/fibre-icon@2x.png 2x, assets/img/fibre-icon@3x.png 3x" /> -->
                        </span>
                        <span class="d-point">{{theONTDiagnostics.diagnosticsResult.simple_diagnostics.olt_light_level?.trim() != 'dBm' ? theONTDiagnostics.diagnosticsResult.simple_diagnostics.olt_light_level : ''}}</span>
                      </section>
                      <section class="diagnostic-point">
                        <span class="d-label">Model: </span>
                        <span class="d-point">{{theONTDiagnostics.diagnosticsResult.full_diagnostics.olt_manufacture}}</span>
                      </section>
                    </section>
                    <section class="diagnostic-point">
                      <span class="d-label">PON Port: </span>
                      <span class="d-point">{{theONTDiagnostics.diagnosticsResult.full_diagnostics.pon_port}}</span>
                    </section>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #extraDiagLoading>
                <p class="mt-2">
                  <fdalib-dot-dot-dot colorStyle="regular" size="regular"></fdalib-dot-dot-dot>
                </p>
              </ng-template>
            </section>
          </div> <!-- row -->
        </div> <!-- container -->
        
        <div class="container">
          <div class="row mt-4" id="new-diagnostic-ports">
            <section class="col-md-8">
              <fdalib-port-selector (portSelection)="onPortSelectorSelection($event)" [portStatuses]="diagnosticsPortStatuses"></fdalib-port-selector>
            </section>
            <section class="col-md-4 d-none d-lg-block mt-auto mb-3">
              <fdalib-icon-button buttonClass="btn-secondary float-right" symbol="refresh" symbolClass="icon-white" label="Refresh" (click)="onDiagnosticsRefresh($event)"></fdalib-icon-button>
            </section>
          </div> <!-- row -->
  
          <div class="row" id="selected-port-details">
            <section class="col-lg-12">
              <section class="details-left">
                <fdalib-port-diagnostics [portDiagnostics]="selectedPortDiagnostics"></fdalib-port-diagnostics>
              </section> <!-- details-left -->
            </section> <!-- col -->
  
            <section class="col-lg-12">
              <section class="details-right">
                <fdalib-port-configuration-information [portConfigDetails]="selectedPortConfigDetails"></fdalib-port-configuration-information>
              </section>
            </section> <!-- col -->
          </div> <!-- row -->
  
        </div> <!-- container -->
  
        <a #ontStateHistoryAnchor></a>
  
        <ng-container *ngIf="(ontStateHistoryDaily | async) as theONTStateHistoryDaily">
          <ng-container *ngIf="theONTStateHistoryDaily.length > 0; else noDailyHistory">
            <div class="container with-separator">
              <div class="row">
                <section class="col-sm-3">
                  <label>ONT Status History</label>
                  <h1>Last {{theONTStateHistoryDaily.length}} Days</h1>
                </section>
              </div> <!-- row -->
  
              <div class="row ont-state-history-wrapper">
                <section class="col-sm-12">
  
                  <fdalib-ont-daily-history [dailyHistory]="theONTStateHistoryDaily" (selectedDay)="onDailyHistorySelection($event)"></fdalib-ont-daily-history>
  
                  <ng-container *ngIf="hourlyHistoryLoading || (ontStateHistoryHourly | async) as theONTStateHistoryHourly">
                    <ng-container *ngIf="!hourlyHistoryLoading; else hourlyLoading">
                      <fdalib-ont-hourly-history [hourlyHistory]="theONTStateHistoryHourly" (selectedHour)="onHourlyHistorySelection($event)"></fdalib-ont-hourly-history>
                    
                      <ng-container *ngIf="(ontStateHistoryAllInHour | async) as theONTStateHistoryAllInHour">
  
                        <ng-container *ngIf="allInHourHistoryValid">
                          <ng-container *ngIf="!allInHourHistoryLoading; else allInHourLoading">
                            <fdalib-ont-hourly-history-details [allInHourEvents]="theONTStateHistoryAllInHour"></fdalib-ont-hourly-history-details>
                          </ng-container>
  
                          <ng-template #allInHourLoading>
                            <section class="loading-label">
                              Loading in-hour data...
                              <fdalib-dot-dot-dot colorStyle="regular"></fdalib-dot-dot-dot>
                            </section>
                          </ng-template>
  
                        </ng-container>
                      </ng-container>
                    </ng-container>
  
                    <ng-template #hourlyLoading>
                      <section class="loading-label">
                        Loading hourly data...
                        <fdalib-dot-dot-dot colorStyle="regular"></fdalib-dot-dot-dot>
                      </section>
                    </ng-template>
  
                  </ng-container>
                  
                </section> <!-- col -->
              </div> <!-- row -->
  
            </div> <!-- container -->
          </ng-container>
        </ng-container>
  
        <ng-template #noDailyHistory>
            <div class="container">
              <div class="row">
                <section class="col-sm-12">
                  <label>ONT Status History</label>
                  <h2>Sorry there was a problem loading history data.</h2>
                </section> <!-- col -->
              </div> <!-- row -->
            </div> <!-- container -->
        </ng-template>
  

        <!-- Faults -->
        <a #faultHistoryAnchor></a>
        <ng-container *ngIf="(ontFaultHistory | async) as theONTFaultHistory; else faultHistoryLoading">
          <ng-container *ngIf="theONTFaultHistory.length > 0; else noFaultHistory">
            <div class="container with-separator">
              <div class="row"> <!-- -->
                <section class="col-sm-12">
                  <label>ONT Fault History</label>
                  <h1>We found {{theONTFaultHistory.length}} fault{{theONTFaultHistory.length == 1 ? '' : 's'}}</h1>
                  <table class="table fault-list-table table-striped table-hover">
                      <thead>
                          <tr>
                              <th>Status</th>
                              <th>Type</th>
                              <th>Northpower Ref.</th>
                              <th>Your Ref.</th>
                              <th>Address</th>
                              <th>Fault Raised</th>
                              <th>Service Restored</th>
                              <th>Description</th>
                              <!-- <th>More</th> -->
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let thisFault of theONTFaultHistory" (click)="onFaultHistoryClick(thisFault)">
                              <td><fdalib-fault-status-badge [faultStatus]="thisFault.faultStatus"></fdalib-fault-status-badge></td>
                              <td>{{thisFault.faultType}}</td>
                              <td>{{thisFault.b2bReference}}</td>
                              <td>{{thisFault.rspReference}}</td>
                              <td>{{thisFault.shortAddress}}</td>
                              <td>{{thisFault.faultRaisedDate | date: "dd MMM yyyy"}}</td>
                              <td>{{thisFault.serviceRestoredDate | date: "dd MMM yyyy"}}</td>
                              <td>{{thisFault.faultDescription}}</td>
                              <!-- <td><a *ngIf="thisFault.b2bReference" [attr.href]="env.ssp_base_url + '/v1-2-0/faults_item/' +  thisFault.b2bReference">Details</a></td> -->
                          </tr>
                      </tbody>
                  </table>
                </section>
              </div> <!-- row -->
            </div> <!-- container -->
          </ng-container>
        </ng-container>

        <ng-template #faultHistoryLoading>
          <p>
            Loading fault history
            <fdalib-dot-dot-dot colorStyle="regular"></fdalib-dot-dot-dot>
          </p>
        </ng-template>

        <ng-template #noFaultHistory>
          <div class="container">
            <div class="row">
              <section class="col-sm-12">
                <label>ONT Fault History</label>
                <h2>There are no faults at this location.</h2>
              </section> <!-- col -->
            </div> <!-- row -->
          </div> <!-- container -->
        </ng-template>


        <ng-container *ngIf="this.scenario">
          <div class="container">
            <header class="row">
              <section class="col-sm-12">
                <label>TEST SCENARIO</label>
                <h1>{{this.scenario}}</h1>
              </section>
            </header> 
          </div>
        </ng-container>
  
      </section> <!-- diagnostic-snapshot-panel -->
  
      <section id="historic-alarms-panel">
        <!-- Historic Alarms -->
        <a #historicAlarmsAnchor></a>
        <ng-container *ngIf="(ontHistoricAlarms | async) as theHistoricAlarms; else historicAlarmsLoading">
          <ng-container *ngIf="theHistoricAlarms.length > 0; else noHistoricAlarms">
            <div class="container with-separator mt-4">
              <div class="row"> <!-- -->
                <section class="col-sm-12">
                  <label>Historic Alarms</label>
                  <h1>We found {{theHistoricAlarms.length}} alarm{{theHistoricAlarms.length == 1 ? '' : 's'}}</h1>
                  <table class="table historic-alarms-table table-striped table-hover">
                      <thead>
                          <tr>
                              <th>Time</th>
                              <th>Alarm Name</th>
                              <th>Severity</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let thisAlarm of theHistoricAlarms">
                              <td>{{thisAlarm.alarmTime | date: 'd MMM y, h:mm a'}}</td>
                              <td>{{thisAlarm.alarmName}}</td>
                              <td>{{thisAlarm.severity}}</td>
                              <td>{{thisAlarm.status}}</td>
                          </tr>
                      </tbody>
                  </table>
                </section>
              </div> <!-- row -->
            </div> <!-- container -->
          </ng-container>
        </ng-container>

        <ng-template #historicAlarmsLoading>
          <p>
            Loading historic alarms
            <fdalib-dot-dot-dot colorStyle="regular"></fdalib-dot-dot-dot>
          </p>
        </ng-template>

        <ng-template #noHistoricAlarms>
          <div class="container with-separator mt-4">
            <div class="row">
              <section class="col-sm-12">
                <label>ONT Historic Alarms</label>
                <h2>There are no historic alarms at this location.</h2>
              </section> <!-- col -->
            </div> <!-- row -->
          </div> <!-- container -->
        </ng-template>
      </section> <!-- historic-alarms-panel -->
        
      <!-- DEBUG -->
      <hr style="margin-top: 200px">
      <ng-container *ngIf="!env.production">
        <hr class="mt-4 mb-4">
        <div class="row">
          <div class="col-12">
  
  
            <p>DEBUG</p>
            <p>theQueriedServices: {{theQueriedServices | json}}</p>
            <p>FSL: {{theQueriedServices.fibreServiceLocationIdentifier}}</p>
  
            <ul class="debug-port-icon-list">
              <li class="title d-none d-lg-block"><h3>DEBUG RJ45 Icons</h3></li>
              <li><span class="port-id">1</span><fdalib-rj45-icon iconStatus="provisioned-device-detected"></fdalib-rj45-icon> provisioned-device-detected</li>
              <li><span class="port-id">2</span><fdalib-rj45-icon iconStatus="provisioned-no-device"></fdalib-rj45-icon> provisioned-no-device</li>
              <li><span class="port-id">2</span><fdalib-rj45-icon iconStatus="provisioned-degraded-service"></fdalib-rj45-icon> provisioned-degraded-service</li>
              <li><span class="port-id">3</span><fdalib-rj45-icon iconStatus="not-your-service-device-detected"></fdalib-rj45-icon> not-your-service-device-detected</li>
              <li><span class="port-id">3</span><fdalib-rj45-icon iconStatus="not-your-service-no-device"></fdalib-rj45-icon> not-your-service-no-device</li>
              <li><span class="port-id">5</span><fdalib-rj45-icon iconStatus="not-provisioned-device-detected"></fdalib-rj45-icon> not-provisioned-device-detected</li>
              <li><span class="port-id">5</span><fdalib-rj45-icon iconStatus="not-provisioned-no-device"></fdalib-rj45-icon> not-provisioned-no-device</li>
              <li><span class="port-id">6</span><fdalib-rj45-icon iconStatus="not-testable"></fdalib-rj45-icon> not-testable</li>
            </ul>
  
            <ul class="debug-port-icon-list mb-4">
              <li class="title d-none d-lg-block"><h3>DEBUG RJ11 Icons</h3></li>
              <li><span class="port-id">1</span><fdalib-rj11-icon iconStatus="provisioned"></fdalib-rj11-icon> provisioned</li>
              <li><span class="port-id">2</span><fdalib-rj11-icon iconStatus="provisioned-not-connected"></fdalib-rj11-icon> provisioned-not-connected</li>
              <li><span class="port-id">3</span><fdalib-rj11-icon iconStatus="not-your-service"></fdalib-rj11-icon> not-your-service</li>
              <li><span class="port-id">5</span><fdalib-rj11-icon iconStatus="not-provisioned"></fdalib-rj11-icon> not-provisioned</li>
              <li><span class="port-id">6</span><fdalib-rj11-icon iconStatus="not-testable"></fdalib-rj11-icon> not-testable</li>
            </ul>
            
            <h5>Data Port Status Badges</h5>
            <ul class="debug-port-status-badge-list mb-4">
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="provisioned-device-detected"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="provisioned-no-device"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="provisioned-degraded-service"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="not-your-service-device-detected"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="not-your-service-no-device"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="not-provisioned-device-detected"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="not-provisioned-no-device"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="loading"></fdalib-data-port-status-badge></li>
              <li><fdalib-data-port-status-badge ngClass="data-port-status-badge" status="not-testable"></fdalib-data-port-status-badge></li>
            </ul>
  
            <h5>Voice Port Status Badges</h5>
            <ul class="debug-port-status-badge-list mb-4">
              <li><fdalib-voice-port-status-badge ngClass="voice-port-status-badge" status="provisioned"></fdalib-voice-port-status-badge></li>
              <li><fdalib-voice-port-status-badge ngClass="voice-port-status-badge" status="provisioned-not-connected"></fdalib-voice-port-status-badge></li>
              <li><fdalib-voice-port-status-badge ngClass="voice-port-status-badge" status="not-your-service"></fdalib-voice-port-status-badge></li>
              <li><fdalib-voice-port-status-badge ngClass="voice-port-status-badge" status="not-provisioned"></fdalib-voice-port-status-badge></li>
              <li><fdalib-voice-port-status-badge ngClass="voice-port-status-badge" status="loading"></fdalib-voice-port-status-badge></li>
              <li><fdalib-voice-port-status-badge ngClass="voice-port-status-badge" status="not-testable"></fdalib-voice-port-status-badge></li>
            </ul>
  
            <h3 class="legend mt-4">ont state current</h3>
            <pre>{{ontStateCurrent | async | json}}</pre>
          
            <h3 class="legend mt-4">queried services</h3>
            <pre>{{queriedServices | async | json}}</pre>
  
            <h3 class="legend mt-4">ont diagnostics</h3>
            <pre>{{ontDiagnostics | async | json}}</pre>
          
            <h3 class="legend mt-4">queried place</h3>
            <pre>{{queriedPlace | async | json}}</pre>
          
          </div> <!-- col -->
        </div> <!-- row -->
      </ng-container> <!-- !env.production -->
  
  </ng-container> <!-- !NoData -->
  
  
  
</ng-container> <!-- queriedServices -->
  
<ng-template #loading>
    <div class="container">
      <div class="row mt-4 mb-4 pb-4">
        <div class="col-12">
          <h2 class="heading">Loading services for your location &hellip;</h2>
          <!-- <img src="{{env.ssp_base_url}}/core/v1-2-0/img/ajax-loader.gif"/> -->
          <fdalib-dot-dot-dot colorStyle="regular" size="large"></fdalib-dot-dot-dot>
        </div> <!-- col -->
      </div> <!-- row -->
    </div> <!-- container -->
</ng-template>
  
<ng-template #servicesLoadingFailed>
  <div class="container">
    <div class="row mt-4 mb-4 pb-4">
      <div class="col-12">
        <h1>Sorry</h1>
        <h2>There was a problem loading services for your location.</h2>
      </div> <!-- col -->
    </div> <!-- row -->
  </div> <!-- container -->
</ng-template>
  
  
  
  
