<h2 class="data-port-status-badge {{_status}} pt-2 pb-2" title="{{badgeTitle | titlecase}}"><span>{{_status | portStatus}}</span></h2>

<section class="data-port-status-key">

  <section class="status-key-item xx-device-status">
    <span class="key-icon">
      <fdalib-rj45-icon [iconStatus]="_status" iconWidth="50" iconHeight="50"></fdalib-rj45-icon>
    </span>
    <span class="key-label">
      <ng-container *ngIf="provisioningStatus && provisioningStatus != 'not-testable'">
        {{provisioningStatus | statusToLabel}}<br>
      </ng-container>
      <ng-container *ngIf="deviceStatus">
        {{deviceStatus | statusToLabel}}<br>
      </ng-container>
      <ng-container *ngIf="serviceStatus">
        {{serviceStatus | statusToLabel}}
      </ng-container>
    </span>
  </section>

  <ng-container *ngIf="provisioningStatus == 'not-provisioned' && deviceStatus == 'device-detected'">
    <p class="alert alert-danger">Is the device connected to the wrong port?</p>
  </ng-container>

</section>
