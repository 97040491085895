import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const oidcToken = this.oidcSecurityService.getIdToken();
    // console.log('TokenInterceptor:: got oidcToken', {oidcToken});
    if (!oidcToken) {
      console.log('TokenInterceptor NO oidcToken');
      return next.handle(req);
    }

    if (!req.url.match(environment.fdaapi_server)) {
      console.log('TokenInterceptor endpoint not FDA API. No auth token added.');
      return next.handle(req);
    }

    console.log('TokenInterceptor adding Authorization header.');
    const req1 = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${oidcToken}`),
    });
    return next.handle(req1);
  }
}
