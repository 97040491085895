export class ONTDiagnosisClass {

  private _diagnosis: ONTDiagnosisEnum;
  private _problems: string[] = [];
  private _remarks: string[] = [];

  constructor(theDiagnosis: ONTDiagnosisEnum)
  {
    console.log('ONTDiagnosisClass constructor got', theDiagnosis);
    this._diagnosis = theDiagnosis; 
  }

  get diagnosis(): ONTDiagnosisEnum {
      return this._diagnosis;
  }
  set diagnosis(value: ONTDiagnosisEnum) {
      this._diagnosis = value;
  }

  // get diagnosisValue(): string {
  //   return ONTDiagnosisDescriptionEnum[this._diagnosis];
  // }
  
  get diagnosisShort(): string {
      return ONTDiagnosisShortEnum[this._diagnosis];
  }

  get diagnosisSummary(): string {
      return ONTDiagnosisSummaryEnum[this._diagnosis];
  }

  get diagnosisDescription(): string {
      return ONTDiagnosisDescriptionEnum[this._diagnosis];
  }

  public addProblem(problem: string) : void
  {
    this._problems.push(problem);
  }

  public getProblems() : string[]
  {
    return this._problems;
  }

  public addRemark(remark: string) : void
  {
    this._remarks.push(remark);
  }

  public getRemarks() : string[]
  {
    return this._remarks;
  }

  private transformToString(diagnosis: ONTDiagnosisEnum) : string
  {
    if (ONTDiagnosisEnum[diagnosis])
    {
      return ONTDiagnosisEnum[diagnosis];
    }
    return '';
  }

  private transformFromString(diagnosisString: string) : ONTDiagnosisEnum | null
  {
    if (ONTDiagnosisEnum[diagnosisString])
    {
      return ONTDiagnosisEnum[diagnosisString];
    }
    return null;
  }
}

// type ONTDiagnosisType = "In Service" | "Not In Service" | "Power Problem" | "Fibre Network Problem" | "Wrong Port";
// const ONTDiagnosisType = {
//     get InService(): ONTDiagnosisType { return "In Service"; },
//     get NotInService(): ONTDiagnosisType { return "Not In Service"; },
//     get PowerProblem(): ONTDiagnosisType { return "Power Problem"; },
//     get FibreNetworkProblem(): ONTDiagnosisType { return "Fibre Network Problem"; },
//     get WrongPort(): ONTDiagnosisType { return "Wrong Port"; },
// }

export enum ONTDiagnosisEnum {
  InService           = 'In Service',
  WrongPort           = 'Wrong Port',
  NotInService        = 'Not In Service',
  PowerProblem        = 'Power Problem',
  FibreNetworkProblem = 'Fibre Network Problem',
  NotTestable         = 'Not Testable',
};

export enum ONTDiagnosisShortEnum {
  'In Service'            = 'in-service',
  'Not In Service'        = 'not-in-service',
  'Power Problem'         = 'power-problem',
  'Fibre Network Problem' = 'fibre-network-problem',
  'Wrong Port'            = 'wrong-port',
  'Not Testable'          = 'not-testable',
};

export enum ONTDiagnosisSummaryEnum {
  'In Service'            = 'The equipment is in service.',
  'Not In Service'        = 'The equipment is not in service.',
  'Power Problem'         = 'There is a power problem with the equipment.',
  'Fibre Network Problem' = 'A fibre network problem is affecting the equipment.',
  'Wrong Port'            = 'Customer Premise Equipment may be plugged into the wrong port.',
  'Not Testable'          = 'We could not reach the equipment to diagnose problems.',
};

export enum ONTDiagnosisDescriptionEnum {
  'In Service'            = 'The equipment is in service.',
  'Not In Service'        = 'The equipment is not in service.',
  'Power Problem'         = 'There is a power problem with the equipment.',
  'Fibre Network Problem' = 'A fibre network problem is affecting the equipment.',
  'Wrong Port'            = 'Customer Premise Equipment may be plugged into the wrong port.',
  'Not Testable'          = 'We could not reach the equipment to diagnose problems.',
};





