import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { FdalibNl2brPipe } from './pipe/nl2br.pipe';
import { FdalibPortStatusPipe } from './pipe/port-status.pipe';
import { FdalibStatusToLabelPipe } from './pipe/status-to-label.pipe';
import { FdalibSafePipe } from './pipe/safe.pipe';

import { FdalibONTStatusBadgeComponent } from './component/fdalib-ont-status-badge/fdalib-ont-status-badge.component';
import { FdalibToastContainerComponent } from './component/fdalib-toast-container/fdalib-toast-container.component';

import { FdalibOntHistorySummaryComponent } from './component/fdalib-ont-history-summary/fdalib-ont-history-summary.component';
import { FdalibOntDailyHistoryComponent } from './component/fdalib-ont-daily-history/fdalib-ont-daily-history.component';
import { FdalibOntHourlyHistoryComponent } from './component/fdalib-ont-hourly-history/fdalib-ont-hourly-history.component';
import { FdalibOntHourlyHistoryDetailsComponent } from './component/fdalib-ont-hourly-history-details/fdalib-ont-hourly-history-details.component';
import { FdalibRj45IconComponent } from './component/fdalib-rj45-icon/fdalib-rj45-icon.component';
import { FdalibRj11IconComponent } from './component/fdalib-rj11-icon/fdalib-rj11-icon.component';
import { FdalibIconButtonComponent } from './component/fdalib-icon-button/fdalib-icon-button.component';
import { FdalibPortSelectorComponent } from './component/fdalib-port-selector/fdalib-port-selector.component';
import { FdalibPortDiagnosticsComponent } from './component/fdalib-port-diagnostics/fdalib-port-diagnostics.component';
import { FdalibPortConfigurationInformationComponent } from './component/fdalib-port-configuration-information/fdalib-port-configuration-information.component';
import { FdalibDataPortStatusBadgeComponent } from './component/fdalib-data-port-status-badge/fdalib-data-port-status-badge.component';
import { FdalibVoicePortStatusBadgeComponent } from './component/fdalib-voice-port-status-badge/fdalib-voice-port-status-badge.component';
import { FdalibDotDotDotComponent } from './component/fdalib-dot-dot-dot/fdalib-dot-dot-dot.component';
import { FdalibIconSymbolComponent } from './component/fdalib-icon-symbol/fdalib-icon-symbol.component';
import { FdalibUserProfileComponent } from './component/fdalib-user-profile/fdalib-user-profile.component';
import { FdalibOntModelDetailsComponent } from './component/fdalib-ont-model-details/fdalib-ont-model-details.component';
import { FdalibFaultStatusBadgeComponent } from './component/fdalib-fault-status-badge/fdalib-fault-status-badge.component';

@NgModule({
  declarations: [
    FdalibNl2brPipe,
    FdalibPortStatusPipe,
    FdalibStatusToLabelPipe,
    FdalibSafePipe,
    FdalibToastContainerComponent,
    FdalibONTStatusBadgeComponent,
    FdalibOntHistorySummaryComponent,
    FdalibOntDailyHistoryComponent,
    FdalibOntHourlyHistoryComponent,
    FdalibOntHourlyHistoryDetailsComponent,
    FdalibRj45IconComponent,
    FdalibRj11IconComponent,
    FdalibIconButtonComponent,
    FdalibPortSelectorComponent,
    FdalibPortDiagnosticsComponent,
    FdalibPortConfigurationInformationComponent,
    FdalibDataPortStatusBadgeComponent,
    FdalibVoicePortStatusBadgeComponent,
    FdalibDotDotDotComponent,
    FdalibIconSymbolComponent,
    FdalibUserProfileComponent,
    FdalibOntModelDetailsComponent,
    FdalibFaultStatusBadgeComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
  ],
  exports: [
    //
    // ng generate doesn't automatically add components to the exports list,
    // you need to do it yourself.
    //
    FdalibNl2brPipe,
    FdalibPortStatusPipe,
    FdalibStatusToLabelPipe,
    FdalibSafePipe,
    FdalibToastContainerComponent,
    FdalibONTStatusBadgeComponent,
    FdalibOntHistorySummaryComponent,
    FdalibOntDailyHistoryComponent,
    FdalibOntHourlyHistoryComponent,
    FdalibOntHourlyHistoryDetailsComponent,
    FdalibRj45IconComponent,
    FdalibRj11IconComponent,
    FdalibIconButtonComponent,
    FdalibPortSelectorComponent,
    FdalibPortDiagnosticsComponent,
    FdalibPortConfigurationInformationComponent,
    FdalibDataPortStatusBadgeComponent,
    FdalibVoicePortStatusBadgeComponent,
    FdalibDotDotDotComponent,
    FdalibIconSymbolComponent,
    FdalibUserProfileComponent,
    FdalibOntModelDetailsComponent,
    FdalibFaultStatusBadgeComponent,
  ]
})
export class SharedLibModule { }
