<ng-container *ngIf="dailyHistory.length > 0; else noDailyHistory">
    <section class="ont-state-history-daily">
      <h4>{{dailyHistory[0].intervalStartMoment.format('DD MMM')}} - {{dailyHistory[dailyHistory.length-1].intervalStartMoment.format('DD MMM')}}</h4>
      <ul>
        <li *ngFor="let thisDay of dailyHistory; index as i;" class="ont-state {{thisDay._status}} {{thisDay.intervalStart == theSelectedDay?.intervalStart ? 'selected' : ''}}" (click)="onDayClick(thisDay)" title="{{thisDay.intervalStartMoment.format('D MMM')}} {{thisDay.statusName}}">
          {{thisDay.intervalStartMoment.format('D')}}<br>
          <span class="month">{{thisDay.intervalStartMoment.format('MMM')}}</span>
        </li>
      </ul>
    </section> <!-- ont-state-history-daily -->
  </ng-container>
  
  <ng-template #noDailyHistory>
    <p>-- Failed to load daily history --</p>
  </ng-template>
  
  
