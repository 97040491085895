import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ONTStatusClass } from '../../class/fdalib-ont-status-class';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';

@Component({
  selector: 'fdalib-ont-history-summary',
  templateUrl: './fdalib-ont-history-summary.component.html',
  styleUrls: ['./fdalib-ont-history-summary.component.scss']
})
export class FdalibOntHistorySummaryComponent implements OnInit {

  @Input() dailyHistory: ONTStatusClass[];
  @Output() summaryClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fdaLog: FdalibLoggerService) { }

  ngOnInit() {
  }

  public onSummaryClick(event: any)
  {
    this.summaryClick.next(event);
  }

}
