import { Component, OnInit } from '@angular/core';

import { FdaVersionInfoService } from '../../service/fda-version-info.service';

@Component({
  selector: 'app-fda-bottom-nav',
  templateUrl: './fda-bottom-nav.component.html',
  styleUrls: ['./fda-bottom-nav.component.scss']
})
export class FdaBottomNavComponent implements OnInit {
  public thisYear: string;
  public versionHash: string = '';
  constructor(public versionInfo: FdaVersionInfoService) {
    this.thisYear = new Date().getFullYear().toString();
   }

  ngOnInit(): void {
    const theVersionInfo = this.versionInfo.getVersionInfo();
    this.versionHash = theVersionInfo.hash;
  }

}
