import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FdaWelcomeComponent } from './component/fda-welcome/fda-welcome.component';
import { FdaAuthorizedComponent } from './component/fda-authorized/fda-authorized.component';
import { FdaUnauthorizedComponent } from './component/fda-unauthorized/fda-unauthorized.component';
import { FdaOntDiagnosticsComponent } from './component/fda-ont-diagnostics/fda-ont-diagnostics.component';
import { FdaFindOntComponent } from './component/fda-find-ont/fda-find-ont.component';
import { FdaListFaultsComponent } from './component/fda-list-faults/fda-list-faults.component';
import { OidcAuthGuardService } from './service/oidc-auth-guard.service';
import { FdalibUserProfileComponent } from 'projects/shared-lib/src/lib/component/fdalib-user-profile/fdalib-user-profile.component';
// import { FdalibOntModelDetailsComponent } from 'projects/shared-lib/src/lib/component/fdalib-ont-model-details/fdalib-ont-model-details.component';
import { FdalibOntModelDetailsComponent } from '@northpower/fda-shared-lib';


const routes: Routes = [
    {path: 'unauthorized',      component: FdaUnauthorizedComponent},
    // {path: 'authorized',        component: FdaAuthorizedComponent,           canActivate: [OidcAuthGuardService]},
    {path: '',                  component: FdaWelcomeComponent},
    {path: 'user_profile',      component: FdalibUserProfileComponent,       canActivate: [OidcAuthGuardService]},
    {path: 'ont_diagnostics',   component: FdaOntDiagnosticsComponent,       canActivate: [OidcAuthGuardService]},
    {path: 'find_ont',          component: FdaFindOntComponent,              canActivate: [OidcAuthGuardService]},
    {path: 'list_faults',       component: FdaListFaultsComponent,           canActivate: [OidcAuthGuardService]},
    {path: 'ont_model_details', component: FdalibOntModelDetailsComponent,   canActivate: [OidcAuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }


