import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { FdalibLoggerService } from '../service/fdalib-logger.service';

@Injectable()
export class FdalibHttpInterceptor implements HttpInterceptor {

  constructor(private fdalibLogger: FdalibLoggerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.fdalibLogger.debug('FdalibHttpInterceptor-->> (ok)', event);
          //
          // Testing
          //
          let fakeError: HttpErrorResponse = new HttpErrorResponse({error: { error: 'fdalib Fake Error', message: 'Just another fdalib Fake Error' }, headers: event.headers, status: 404, statusText: 'This is a Fake Error', url: event.url});
          if (event.body && event.body.ONTServices        && false) { throw fakeError; }
          if (event.body && event.body.queryPlaceResponse && false) { throw fakeError; }
          if (event.body && event.body.ontStateCurrent    && false) { throw fakeError; }
          if (event.body && event.body.diagnosticsResult  && false) { throw fakeError; }
          if (event.body && event.body.ontStateHistory    && false) { throw fakeError; }
          //
          // End Testing
          //
        }
        // this.fdalibLogger.debug('FdalibHttpInterceptor returns');
        return event;
      }),
      catchError((error: any) => {
        // this.fdalibLogger.error_no_log('FdalibHttpInterceptor catchError error', error); // Mick Temp

        let errorHeading: string = 'There was a problem loading data.';
        let errorDetail: string = '';

        if (error.dataError || error.systemError)
        {
          errorDetail = (error.dataError ? 'Data' : 'System') + ' Error: ' + error.message;
        }
        // else if (error.error)
        // {
        //   errorDetail = (error.error && error.error.message) ? 'Error Message: ' + error.error.message : 'Message: ' + error.message;
        // }
        else
        {
          errorDetail = 'Message: ' + error.message;
        }
        this.fdalibLogger.error_no_log(errorHeading, errorDetail);

        // console.log('FdalibHttpInterceptor catchError error', error);
        // console.log('FdalibHttpInterceptor catchError req', req);

        //
        // Fault has been handled here in the interceptor. Just tell the caller a fault happened
        // but don't pass back any fault information.
        //
        const fdalibErrorEvent: HttpResponse<string> = new HttpResponse({body: 'FdalibHttpError'});
        // return of(fdalibErrorEvent); // Mick Temp: Test this completely new error handling
        // return throwError(fdalibErrorEvent);
        return throwError(error);
      })
    );
  }
}



