import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'fdalib-fdalib-ont-model-details',
  templateUrl: './fdalib-ont-model-details.component.html',
  styleUrls: ['./fdalib-ont-model-details.component.scss']
})
export class FdalibOntModelDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  public ontModel: string;
  public ontModelLabel: string;
  public ontImageFile: string;

  ngOnInit() {
    this.ontModel = this.route.snapshot.queryParamMap.get("model");

    this.ontImageFile = this.getImageFile(this.ontModel);
    this.ontModelLabel = this.getModelLabel(this.ontModel);
  }


  private getImageFile(ontModel: string) : string
  {
    let result: string = 'unknown-900x675.jpg';
    switch (ontModel)
    {
      case 'calix-t063g':
        result = 'ont-t063-697x523.jpg';
        break;
      case 'calix-t072g':
        result = 'ont-t072-900x675.jpg';
        break;
      case 'calix-812g':
        result = 'ont-812gv2-821x616.jpg';
        break;
    }
    return result;
  }

  private getModelLabel(ontModel: string) : string
  {
    let result: string = 'Unknown';
    switch (ontModel)
    {
      case 'calix-t063g':
        result = 'Calix T063G';
        break;
      case 'calix-t072g':
        result = 'Calix T072G';
        break;
      case 'calix-812g':
        result = 'Calix 812Gv2';
        break;
    }
    return result;
  }
}
