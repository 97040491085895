<ngb-toast
  *ngFor="let toast of fdalibToastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hide)="fdalibToastService.remove(toast)"
>

  <button type="button" class="close" (click)="hide(toast)"><span aria-hidden="true">&times;</span></button>

  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <h4>{{ toast.textOrTpl }}</h4>
    <p *ngIf="toast.detail">{{toast.detail}}</p>
  </ng-template>
</ngb-toast>
