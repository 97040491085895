<ng-container *ngIf="(portConfigDetails | async) as thePortConfigDetails; else noPortSelected" id="selected-port-details">

    <ng-container *ngIf="thePortConfigDetails.serviceExists; else noServiceExists">
  
      <ng-container *ngIf="thePortConfigDetails.brand; else notYourService">
  
        <table class="table config-table table-hover table-striped table-borderless">
          <thead class="thead-dark owned">
            <th colspan="2">
              Configuration Information
              <span class="float-right">{{thePortConfigDetails.portTitle}}</span>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Brand</td>
              <td>{{thePortConfigDetails.brand}}</td>
            </tr>
            <ng-container *ngIf="thePortConfigDetails.lanPort > 0">
              <tr>
                <td>Package</td>
                <td>{{thePortConfigDetails.package}}</td>
              </tr>
              <tr>
                <td>Service Type</td>
                <td>{{thePortConfigDetails.serviceType}}</td>
              </tr>
            </ng-container>
            <tr>
              <td>Service Status</td>
              <td>{{thePortConfigDetails.serviceStatus}}</td>
            </tr>
            <tr>
              <td>Fibre Service ID</td>
              <td>{{thePortConfigDetails.fibreServiceId}}</td>
            </tr>
            <tr>
              <td>Fibre Circuit ID</td>
              <td>{{thePortConfigDetails.fibreCircuitId}}</td>
            </tr>
            <tr>
              <td>Fibre Circuit Type</td>
              <td>{{thePortConfigDetails.fibreCircuitType}}</td>
            </tr>
            <tr>
              <td>Remote ID</td>
              <td>{{thePortConfigDetails.remoteId}}</td>
            </tr>
            <ng-container *ngIf="thePortConfigDetails.lanPort > 0">
              <tr>
                <td>UNI Tagging Mode</td>
                <td>{{thePortConfigDetails.UNITaggingMode}}</td>
              </tr>
            </ng-container>
            <tr>
              <td>Handover Link ID</td>
              <td>{{thePortConfigDetails.handoverLinkId}}</td>
            </tr>
            <tr>
              <td>SVLAN</td>
              <td>{{thePortConfigDetails.SVLAN}}</td>
            </tr>
            <tr>
              <td>CVLAN</td>
              <td>{{thePortConfigDetails.CVLAN}}</td>
            </tr>
            <tr>
              <td>Last Provisioning Change</td>
              <td>{{thePortConfigDetails.lastModified | date:"d MMMM yyyy"}}</td>
            </tr>
          </tbody>
        </table>
  
      </ng-container>
  
      <ng-template #notYourService>
        <table class="table config-table table-hover table-striped table-borderless">
          <thead class="thead-dark">
            <th colspan="2">
              Configuration Information
                <span class="float-right">{{thePortConfigDetails.portTitle}}</span>
            </th>
          </thead>
          <tbody>
            <td colspan="2">
              This port is provisioned to another retail service provider.
            </td>
          </tbody>
          </table>
      </ng-template>
  
    </ng-container>
  
    <ng-template #noServiceExists>
      <table class="table config-table table-hover table-striped table-borderless">
        <thead class="thead-dark">
          <th colspan="2">
            Configuration Information
              <span class="float-right">{{thePortConfigDetails.portTitle}}</span>
          </th>
        </thead>
        <tbody>
          <td colspan="2">
            No service is provisioned on this port.
          </td>
        </tbody>
        </table>
    </ng-template>
  
  </ng-container>
  
  <ng-template #noPortSelected>
    <table class="table config-table table-hover table-striped table-borderless">
      <thead class="thead-dark">
        <th colspan="2">
          Please select an ONT port.
        </th>
      </thead>
      </table>
  </ng-template>
  
