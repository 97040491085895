import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FdaAuthorizedComponent } from './component/fda-authorized/fda-authorized.component';
import { FdaOntDiagnosticsComponent } from './component/fda-ont-diagnostics/fda-ont-diagnostics.component';
import { FdaWelcomeComponent } from './component/fda-welcome/fda-welcome.component';
import { FdaLoginStatusComponent } from './component/fda-top-nav/fda-top-nav.component';
import { FdaUnauthorizedComponent } from './component/fda-unauthorized/fda-unauthorized.component';
import { FdaFindOntComponent } from './component/fda-find-ont/fda-find-ont.component';
import { FdaListFaultsComponent } from './component/fda-list-faults/fda-list-faults.component';
import { TokenInterceptor } from './interceptor/token-interceptor';
import { FdaBottomNavComponent } from './component/fda-bottom-nav/fda-bottom-nav.component';
import { FdaErrorHandlerService } from './service/fda-error-handler.service';
import { FdalibHttpInterceptor } from '@northpower/fda-shared-lib';

//
// imports from fds'a shared-lib. Maybe need to do this differently if we publish the library and then install via npm
//
import { SharedLibModule } from '@northpower/fda-shared-lib'; // shared-lib path is set up in tsconfig.json
 
export function configureAuth(oidcConfigService: OidcConfigService) {
    return () => {
      console.log('APP_INITIALISER configureAuth()', {environment});
        oidcConfigService.withConfig({
            stsServer: environment.security_token_server,
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: environment.fda_client_id,
            scope: 'openid profile email np_fibre',
            responseType: 'code',
            silentRenew: false, // was true
            logLevel: LogLevel.Debug,
            storage: localStorage,
            //
            // Done dynamically by Login button (FdaLoginStatusComponent)
            //
            // customParams: {
            //   login_hint: 'FMC:mick@websmartdesign.nz',
            // },
        });
    }
}
 
@NgModule({
  declarations: [
    AppComponent,
    FdaAuthorizedComponent,
    FdaOntDiagnosticsComponent,
    FdaWelcomeComponent,
    FdaLoginStatusComponent,
    FdaUnauthorizedComponent,
    FdaFindOntComponent,
    FdaListFaultsComponent,
    FdaBottomNavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule.forRoot(),
    NgbModule,
    FormsModule, 
    ReactiveFormsModule,
    SharedLibModule,
  ],
  providers: [
    OidcConfigService,
    {
        provide: APP_INITIALIZER,
        useFactory: configureAuth,
        deps: [OidcConfigService],
        multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FdalibHttpInterceptor,
      multi: true
    },
    { provide: 'FDA_ENV', useValue: environment },
    { provide: ErrorHandler, useClass: FdaErrorHandlerService },
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  public env = environment;
}
