<ng-container *ngIf="_iconStatus != 'loading'; else loadingIndicator">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:ev="http://www.w3.org/2001/xml-events"
        [attr.width]="iconWidth" [attr.height]="iconHeight"
        [attr.viewBox]="viewBox" xml:space="preserve" color-interpolation-filters="sRGB" class="rj45-svg" [ngClass]="_iconStatus">
  
      <title>{{iconLabel}}: {{iconTitle | titlecase}}</title>
  
      <g class="rj45-shape" [attr.transform]="'scale(' + iconScale + ')'">
        <rect x="-12" y=" -2" width="24" height=" 4" rx="0.5" />
        <rect x="-11" y=" -6" width="22" height="15" rx="2" />
        <rect x=" -6" y=" -8" width="12" height=" 3" rx="0.5" />
        <rect x=" -3" y="-10" width=" 6" height=" 3" rx="0.5" />
      </g>
      <g class="rj45-connector" [attr.transform]="'scale(' + iconScale + ')'">
        <rect x="-7.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x="-5.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x="-3.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x="-1.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 0.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 2.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 4.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 6.5" y="1" width="1" height="5" rx="0.5" shape-rendering="crispEdges" />
      </g>
  
      <ng-container *ngIf="decorateIcon">
        <ng-container *ngIf="iconDisabled">
          <g class="rj45-disabled" transform="translate(12, -8) rotate(45) scale(0.4) ">
            <circle cx="0" cy="0" r="18" stroke-width="6"/>
            <rect x="-10" y="-3" width="20" height="6" />
            <rect x="-3" y="-10" width="6" height="20" />
          </g>
        </ng-container>
  
        <ng-container *ngIf="iconMinus">
          <g class="rj45-minus" transform="translate(12, -8) scale(0.4) ">
            <circle cx="0" cy="0" r="18" stroke-width="6"/>
            <rect x="-8" y="-3" width="16" height="6" />
          </g>
        </ng-container>
  
        <ng-container *ngIf="iconNotYours">
          <g class="rj45-not-yours" transform="translate(15, -11) rotate(45) scale(0.5) ">
            <circle cx="0" cy="0" r="18" stroke-width="6"/>
            <rect x="-16" y="-3" width="32" height="6" />
          </g>
        </ng-container>
  
        <ng-container *ngIf="iconNotify">
          <g class="rj45-notify" transform="translate(15, -11) scale(0.5) ">
            <circle cx="0" cy="0" r="16" stroke-width="6" />
          </g>
        </ng-container>
      </ng-container>
  
    </svg>
  </ng-container>
  
  <ng-template #loadingIndicator>
    <section style="width: 60px; height: 60px; padding-top: 20px">
      <ng-container *ngIf="iconSelected | async">
        <fdalib-dot-dot-dot colorStyle="regular" size="small"></fdalib-dot-dot-dot>
      </ng-container>
    </section>
  </ng-template>
  
  
