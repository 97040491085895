<ng-container *ngIf="userData | async as asyncUserData; else noUserData">
    <h4>{{asyncUserData.app_user_name | titlecase}}</h4>
    <p>Your email address is {{asyncUserData.app_email}}.</p>
    <p>You are logged in via {{asyncUserData.app}}. </p>

    <ng-container *ngIf="asyncUserData.app_roles && asyncUserData.app_roles.includes('admin')">
        <p>You have {{asyncUserData.app_roles.length}} role{{plural_roles}}:</p>
        <ul class="app-user-roles">
            <li *ngFor="let this_role of asyncUserData.app_roles">
                {{this_role}}
            </li>
        </ul>
    </ng-container>

    <!-- <p>DEBUG {{asyncUserData | json}}</p> -->
</ng-container> 
<ng-template #noUserData>
    <p>Sorry, you are not logged in.</p>
</ng-template>
