<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="Northpower Fibre Diagnostics App - fda">
    <meta name="author" content="Northpower">

    <title>Northpower Fibre Diagnostics Application (FDA)</title>

  </head>

  <body class="dark_bg">

    <fdalib-toast-container></fdalib-toast-container>

      <header class="container">
        <nav class="top-nav">
          <app-fda-top-nav></app-fda-top-nav>
        </nav>
      </header> 


    <main role="main" class="container">
      <router-outlet></router-outlet>
    </main>

    <footer class="container">
        <app-fda-bottom-nav></app-fda-bottom-nav>
    </footer>

  </body>
</html>

