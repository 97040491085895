<ng-container *ngIf="(portDiagnostics | async) as thePortDiagnostics; else portDiagnosticsLoading" id="selected-port-diagnostics">

    <table class="table mb-0">
      <thead class="thead no-bold">
        <tr>
          <th>
            Port Diagnostics
            <span class="float-right">{{thePortDiagnostics.portTitle}}</span>
          </th>
        </tr>
      </thead>
    </table>
  
    <!-- <p>DEBUG {{thePortDiagnostics | json}}</p> -->
    <ng-container *ngIf="(thePortDiagnostics.portType == 'Data')" class="data-port">
      <section class="ld-top clearfix">
  
        <section class="diagnostic-points">
          <ng-container *ngIf="(thePortDiagnostics.portDiagnosticStatus != 'not-your-service-device-detected' && thePortDiagnostics.portDiagnosticStatus != 'not-your-service-no-device')">
            <section class="diagnostic-point">
              <span class="d-label">Configured Ethernet: </span>
              <span class="d-point">{{thePortDiagnostics.portSpeedDuplex | titlecase}}</span>
            </section>
  
            <section class="diagnostic-point">
              <span class="d-label">Detected Ethernet: </span>
              <span class="d-point">{{thePortDiagnostics.portState | titlecase}}</span>
            </section>
          </ng-container>
        </section>
  
        <fdalib-data-port-status-badge ngClass="data-port-status-badge" [status]="thePortDiagnostics.portDiagnosticStatus"></fdalib-data-port-status-badge>
  
      </section> <!-- ld-top -->
  
      <!-- <section class="ld-mid">
  
        <section class="traffic-direction">
          <ng-container *ngIf="thePortDiagnostics.fifteenMinStartedAt; else noRecentTraffic">
            <h5>Recent Traffic (since {{thePortDiagnostics.fifteenMinStartedAt | date:'HH:mma' | lowercase}})</h5>
            <section class="direction-arrows">
              <div class="ont-up" title="{{thePortDiagnostics.fifteenMinUpstreamBytes}} at {{thePortDiagnostics.fifteenMinUpstreamRate}} upstream">
                <fdalib-icon-symbol symbol="arrow" symbolClass="{{thePortDiagnostics.fifteenMinUpstreamRateOK ? 'icon-green' : 'icon-black'}}" svgClass="size-50"></fdalib-icon-symbol>
                <p>ONT</p>
              </div>
              <div class="rgw-down" title="{{thePortDiagnostics.fifteenMinDownstreamBytes}} at {{thePortDiagnostics.fifteenMinDownstreamRate}} downstream">
                <p>RGW</p>
                <fdalib-icon-symbol symbol="arrow" symbolClass="{{thePortDiagnostics.fifteenMinDownstreamRateOK ? 'icon-green' : 'icon-black'}}" svgClass="size-50 down"></fdalib-icon-symbol>
              </div>
            </section>
            <section class="traffic-status recent">
              <p class="traffic-success" *ngIf="thePortDiagnostics.fifteenMinUpstreamRateOK && thePortDiagnostics.fifteenMinDownstreamRateOK">Recent Traffic OK</p>
              <p class="traffic-danger" *ngIf="!thePortDiagnostics.fifteenMinUpstreamRateOK">Recent Upstream Traffic Low</p>
              <p class="traffic-danger" *ngIf="!thePortDiagnostics.fifteenMinDownstreamRateOK">Recent Downstream Traffic Low</p>
            </section>
          </ng-container>
          <ng-template #noRecentTraffic>
            <h5>No data for Recent Traffic</h5>
          </ng-template>
        </section>
  
        <section class="traffic-direction">
          <ng-container *ngIf="thePortDiagnostics.oneDayStartedAt; else noDailyTraffic">
            <h5>Daily Traffic (since {{thePortDiagnostics.oneDayStartedAt | date:'HH:mma' | lowercase}})</h5>
            <section class="direction-arrows">
              <div class="ont-up" title="{{thePortDiagnostics.oneDayUpstreamBytes}} upstream">
                <fdalib-icon-symbol symbol="arrow" symbolClass="{{thePortDiagnostics.oneDayUpstreamRateOK ? 'icon-green' : 'icon-black'}}" svgClass="size-50"></fdalib-icon-symbol>
                <p>ONT</p>
              </div>
              <div class="rgw-down" title="{{thePortDiagnostics.oneDayDownstreamBytes}} downstream">
                <p>RGW</p>
                <fdalib-icon-symbol symbol="arrow" symbolClass="{{thePortDiagnostics.oneDayDownstreamRateOK ? 'icon-green' : 'icon-black'}}" svgClass="size-50 down"></fdalib-icon-symbol>
              </div>
            </section>
            <section class="traffic-status recent">
              <p class="traffic-success" *ngIf="thePortDiagnostics.oneDayUpstreamRateOK && thePortDiagnostics.oneDayDownstreamRateOK">Daily Traffic OK</p>
              <p class="traffic-danger" *ngIf="!thePortDiagnostics.oneDayUpstreamRateOK">Daily Upstream Traffic Low</p>
              <p class="traffic-danger" *ngIf="!thePortDiagnostics.oneDayDownstreamRateOK">Daily Downstream Traffic Low</p>
            </section>
          </ng-container>
          <ng-template #noDailyTraffic>
            <h5>No data for Daily Traffic</h5>
          </ng-template>
        </section>
  
        <ng-container *ngIf="false">
          <section class="traffic-usage">
            <ng-container *ngIf="thePortDiagnostics.oneDayStartedAt; else noDailyTraffic">
              <h5></h5>
              <ul class="traffic-list">
                <li class="upload">
                  {{thePortDiagnostics.oneDayUpstreamRate}}
                  <fdalib-icon-symbol symbol="arrow" symbolClass="{{thePortDiagnostics.oneDayUpstreamRateOK ? 'icon-green' : 'icon-gray'}}" svgClass="size-20 float-right"></fdalib-icon-symbol>
                </li>
                <li class="download">
                  {{thePortDiagnostics.oneDayDownstreamRate}}
                  <fdalib-icon-symbol symbol="arrow" symbolClass="{{thePortDiagnostics.oneDayDownstreamRateOK ? 'icon-green' : 'icon-gray'}}" svgClass="size-20 down float-right"></fdalib-icon-symbol>
                </li>
              </ul>
            </ng-container>
          </section>  <!- - traffic-usage - ->
        </ng-container>
        
      </section> <!- - ld-mid - -> -->
  
      <!-- <section class="ld-lower clearfix">
        <ul>
          <ng-container *ngIf="thePortDiagnostics.portDiagnosticStatus == 'not-testable'">
            <li>Port diagnostics unavailable</li>
          </ng-container>
          <ng-container *ngIf="thePortDiagnostics.serviceExists">
          </ng-container>
        </ul>
      </section> -->
  
    </ng-container>
  
    <!-- <ng-container *ngIf="false && thePortDiagnostics.serviceExists">
      <fdalib-icon-button buttonClass="btn-secondary clearfix" symbol="chevron" symbolClass="icon-white" label="Explore Traffic" (click)="onExploreTrafficClick($event)"></fdalib-icon-button>
    </ng-container> -->
  
    <ng-container *ngIf="thePortDiagnostics.portType == 'Voice'" class="voice-port">
      <section class="ld-top clearfix">
  
        <section class="diagnostic-points">
  
          <ng-container *ngIf="thePortDiagnostics.portProvisioned != 'Not provisioned'; else potsPortNotProvisioned">
  
            <ng-container *ngIf="thePortDiagnostics.selectedPotsPortDiagnostics; else potsPortNoDiagnostics">
              <ng-container *ngIf="(thePortDiagnostics.portDiagnosticStatus != 'not-your-service')">
  
                <section class="diagnostic-point">
                  <span class="d-label">Port Type / Description: </span>
                  <span class="d-point">{{thePortDiagnostics.selectedPotsPortDiagnostics.pots_port_type}} / {{thePortDiagnostics.selectedPotsPortDiagnostics.pots_port_description}}</span>
                </section>
  
                <section class="diagnostic-point">
                  <span class="d-label">SIP Service URI: </span>
                  <span class="d-point">{{thePortDiagnostics.selectedPotsPortDiagnostics.pots_port_sip_service?.uri}}</span>
                </section>
  
                <section class="diagnostic-point">
                  <span class="d-label">
                    SIP Status:
                  </span>
                  <span class="d-point">
                    {{thePortDiagnostics.selectedPotsPortDiagnostics.pots_port_sip_service?.service_status | titlecase}}
                    <br>
                    {{thePortDiagnostics.selectedPotsPortDiagnostics.pots_port_sip_service?.hook_status | titlecase}}
                    <ng-container *ngIf="thePortDiagnostics.selectedPotsPortDiagnostics.pots_port_sip_service?.hook_status == 'off-hook'">
                      <br>
                      <img class="tiny-icon ml-2" src="assets/img/phone-off-hook.svg" title="The phone handset is off the hook" />
                    </ng-container>
                  </span>
                </section>
  
                <section class="diagnostic-point">
                  <span class="d-label">SIP Host IP Address: </span>
                  <span class="d-point">{{thePortDiagnostics.selectedPotsPortDiagnostics.sip_ip_host?.current_addres_configuration.address}}</span>
                </section>

              </ng-container>
            </ng-container>
  
            <ng-template #potsPortNoDiagnostics>
              <p>Port diagnostics unavailable.</p>
            </ng-template>
  
          </ng-container>
  
          <ng-template #potsPortNotProvisioned>
            <p></p>
          </ng-template>
  
        </section>
  
       <fdalib-voice-port-status-badge ngClass="voice-port-status-badge" [status]="thePortDiagnostics.portDiagnosticStatus"></fdalib-voice-port-status-badge>
  
      </section> <!-- ld-top -->
  
      <section class="ld-lower clearfix">
        <ng-container *ngIf="false && thePortDiagnostics.serviceActive">
          <fdalib-icon-button buttonClass="btn-danger float-right clearfix" symbol="chevron" symbolClass="icon-white" label="Log Service Fault"></fdalib-icon-button>
        </ng-container>
      </section>
    </ng-container>
  
  </ng-container>
  
  
  
  <ng-template #portDiagnosticsLoading>
    <table class="table config-table table-hover table-striped table-borderless">
      <thead class="thead-dark">
        <th colspan="2">
          Loading <fdalib-dot-dot-dot colorStyle="reverse"></fdalib-dot-dot-dot>
        </th>
      </thead>
      </table>
  </ng-template>
  
  
  
  
