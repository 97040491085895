import { Component, OnInit, Input } from '@angular/core';
import { ONTStatusClass, ONTStatusEnum, ONTStatusJson } from '../../class/fdalib-ont-status-class';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';

@Component({
  selector: 'fdalib-ont-status-badge',
  templateUrl: './fdalib-ont-status-badge.component.html',
  styleUrls: ['./fdalib-ont-status-badge.component.scss']
})
export class FdalibONTStatusBadgeComponent implements OnInit {

  @Input() status: ONTStatusClass;

  constructor(private fdaLog: FdalibLoggerService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
    
}
