import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fdalib-dot-dot-dot',
  templateUrl: './fdalib-dot-dot-dot.component.html',
  styleUrls: ['./fdalib-dot-dot-dot.component.scss']
})
export class FdalibDotDotDotComponent implements OnInit {

  @Input() set colorStyle(theColorStyle: string) {
    this._colorStyle = theColorStyle;
    this.updateFromInputs();
  }
  @Input() set size(theSize: string) {
    this._size = theSize;
    this.updateFromInputs();
  }

  public _size: string;
  public iconWidth: number;
  public iconHeight: number;
  public iconTitle: string = 'Northpower Icon';

  public _colorStyle = 'regular'; // regular, reverse, dark

  constructor() { }

  ngOnInit() {

    this.updateFromInputs();
  }

  updateFromInputs() {

    switch  (this._size)
    {
      case 'tiny':
        this.iconWidth = 24;
        this.iconHeight = 8;
        break;
      case 'small':
        this.iconWidth = 36;
        this.iconHeight = 12;
        break;
      case 'large':
        this.iconWidth = 96;
        this.iconHeight = 32;
        break;
      case 'huge':
        this.iconWidth = 192;
        this.iconHeight = 64;
        break;
      case 'regular': // fall through
      default:
        this.iconWidth = 48;
        this.iconHeight = 16;
    }
  }

}
