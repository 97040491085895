<ng-container *ngIf="faultQueues | async as theFaultQueues; else faultQueuesLoading">

    <!-- <p>Fault Queues: {{theFaultQueues | json}}</p> -->

    <ng-container *ngIf="allFaultsInProgress | async as theFaultsInProgress; else faultsLoading">
        <h4 class="page-title">Customer Faults In Progress</h4>
        <table class="table fault-list-table">
            <thead>
                <tr>
                    <th>Request Type</th>
                    <th>Status</th>
                    <th>Issue Key</th>
                    <th>Summary</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let thisFault of theFaultsInProgress"
                    (click)="onFaultClick(thisFault)">
                    <td>{{thisFault.requestType}}</td>
                    <td>{{thisFault.status}}</td>
                    <td>{{thisFault.issueKey}}</td>
                    <td>{{thisFault.summary}}</td>
                    <td>{{thisFault.dateCreated | date: "dd MMM yyyy"}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>
    
    <ng-template #faultsLoading>
        <p>Loading your faults <fdalib-dot-dot-dot></fdalib-dot-dot-dot></p>
    </ng-template>

</ng-container>

<ng-template #faultQueuesLoading>
    <p>Loading your fault queues <fdalib-dot-dot-dot></fdalib-dot-dot-dot></p>
</ng-template>

