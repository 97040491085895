export class ONTFaultJson {
  public faultType: string; // e.g. Total Loss of Service
  public b2bReference: string; // e.g. 93102
  public rspReference: string; // Spark e.g. might use: 100000629197
  public faultStatus: string;  // In Progress, Restored, Cancelled or Closed.
  public faultRaisedDate: string;
  public serviceRestoredDate: string;
  public faultDescription: string;
  public faultSummary: string;
  public faultSymptom: string;
  public faultCause: string;
  public faultAction: string;
  public shortAddress: string;
  public issueKey: string;
  public l1Contractor: string;
  public fmcUrl: string;
  public cmcUrl: string;
  public isInProgress: string;
  // there are many more fields returned by /api/fault/search and we could add them here...
}


