import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OidcAuthGuardService implements CanActivate {
    constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.oidcSecurityService.isAuthenticated$.pipe(
            map((isAuthorized: boolean) => {
                // console.log('OidcAuthGuardService, canActivate isAuthorized: ' + isAuthorized);
                if (!isAuthorized) {

                    //
                    // We're not logged in. Is there a login hint for FIS?
                    // If so save it in session storage so the login button on the top
                    // nav component can use it when we display /unauthorized.
                    //
                    const login_hint_param = route.queryParamMap.get('login_hint');
                    if (login_hint_param) {
                        sessionStorage.setItem('login_hint', login_hint_param);
                    }

                    //
                    // We're about to redirect the browser to the /unauthorized page.
                    // Remmeber where we are now from so we can redirect back after successful login.
                    //
                    // console.log('OidcAuthGuardService: Setting sessionStorage auth_redirect to ', state.url);
                    sessionStorage.setItem('auth_redirect', state.url);

                    // console.log('OidcAuthGuardService: Unauthorized. Redirecting to /unauthorized');
                    this.router.navigate(['/unauthorized']);
                    return false;
                }
                return true;
            })
        );
    }
}