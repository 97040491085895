import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, delay, tap } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';

import { OidcSecurityService, PublicEventsService, EventTypes } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-fda-welcome',
  templateUrl: './fda-welcome.component.html',
  styleUrls: ['./fda-welcome.component.scss']
})
export class FdaWelcomeComponent implements OnInit {

  public is_logged_in = false;
  private logged_in_obs: Subject<boolean> = new Subject();
  private new_auth_obs: Subject<boolean> = new Subject();

  constructor(private oidcSecurityService: OidcSecurityService, private eventService: PublicEventsService, private router: Router) {
    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthorized) => {
      console.log('FdaWelcomeComponent constructor isAuthenticated$ result', isAuthorized);
      this.is_logged_in = isAuthorized;
      if (isAuthorized)
      {
        this.logged_in_obs.next(true);
      }
    });

    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.NewAuthorizationResult && notification.value && notification.value.authorizationState == 'Authorized'),
        tap((notification) => {
          console.log('FdaWelcomeComponent eventService pipe tap with authorizationState ', notification.value.authorizationState);
          this.new_auth_obs.next(true);
        }),
      )
      .subscribe((notification) => {
        console.log('FdaWelcomeComponent NewAuthorizationResult with authorizationState ', notification.value.authorizationState);
      });
  }

  ngOnInit() {
    combineLatest(this.logged_in_obs, this.new_auth_obs)
      .pipe(
        delay(200) // Enough time for Angular OIDC client to redirect to '/'. Otherwise our redirect gets cancelled.
      )
      .subscribe(
      //
      // We arranged for this observable combination to only emit when
      // logged_in and new_auth are both true.
      //
      ([logged_in, new_auth]) => {
        console.log('FdaWelcomeComponent ngOnInit combineLatest logged_in', logged_in);
        console.log('FdaWelcomeComponent ngOnInit combineLatest new_auth', new_auth);
        this.newAuthorizationRedirect();
      }
    )
  }

  newAuthorizationRedirect() {
    //
    // Look for auth_redirect state, if any, in session storage and clear the storage
    //
    let auth_redirect = sessionStorage.getItem('auth_redirect');
    sessionStorage.removeItem('auth_redirect');
    console.log('FdaWelcomeComponent newAuthorizationRedirect auth_redirect ', auth_redirect);

    if (auth_redirect)
    {
      //
      // We have been asked to redirect after a new authorisation. So do that now.
      //
      console.log('Redirecting to ', auth_redirect);
      this.router.navigateByUrl(auth_redirect);
    }

  }
}