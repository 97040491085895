<ng-container *ngIf="_iconStatus != 'loading'; else loadingIndicator">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:ev="http://www.w3.org/2001/xml-events"
        [attr.width]="iconWidth" [attr.height]="iconHeight"
        [attr.viewBox]="viewBox" xml:space="preserve" color-interpolation-filters="sRGB" class="rj11-svg" [ngClass]="_iconStatus">
  
      <title>{{iconLabel}}: {{iconTitle | titlecase}}</title>
  
      <g class="rj11-shape" [attr.transform]="'scale(' + iconScale + ')'">
        <rect x=" -8" y=" -8" width="15" height="16" rx="2" />
        <rect x=" -6" y="-10" width="11" height="20" rx="2" />
      </g>
      <g class="rj11-connector" [attr.transform]="'scale(' + iconScale + ')'">
        <rect x="-6" y="-5" width="1" height="10" rx="0.5" shape-rendering="crispEdges" />
        <rect x="-4" y="-5" width="1" height="10" rx="0.5" shape-rendering="crispEdges" />
        <rect x="-2" y="-5" width="1" height="10" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 0" y="-5" width="1" height="10" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 2" y="-5" width="1" height="10" rx="0.5" shape-rendering="crispEdges" />
        <rect x=" 4" y="-5" width="1" height="10" rx="0.5" shape-rendering="crispEdges" />
      </g>
  
      <ng-container *ngIf="iconDisabled">
        <g class="rj11-disabled" transform="translate(8, -8) rotate(45) scale(0.4) ">
          <circle cx="0" cy="0" r="18" stroke-width="6"/>
          <rect x="-10" y="-3" width="20" height="6" />
          <rect x="-3" y="-10" width="6" height="20" />
        </g>
      </ng-container>
  
      <ng-container *ngIf="iconMinus">
        <g class="rj11-minus" transform="translate(8, -8) scale(0.4) ">
          <circle cx="0" cy="0" r="18" stroke-width="6"/>
          <rect x="-8" y="-3" width="16" height="6" />
        </g>
      </ng-container>
  
      <ng-container *ngIf="iconNotYours">
        <g class="rj11-not-yours" transform="translate(11, -11) rotate(45) scale(0.5) ">
          <circle cx="0" cy="0" r="18" stroke-width="6"/>
          <rect x="-16" y="-3" width="32" height="6" />
        </g>
      </ng-container>
  
      <ng-container *ngIf="iconNotify">
        <g class="rj11-notify" transform="translate(11, -11) scale(0.5) ">
          <circle cx="0" cy="0" r="16" stroke-width="6" />
        </g>
      </ng-container>
  
    </svg>
  </ng-container>
  
  <ng-template #loadingIndicator>
    <section style="width: 60px; height: 60px">
      <ng-container *ngIf="iconSelected | async">
        <fdalib-dot-dot-dot colorStyle="regular" size="small"></fdalib-dot-dot-dot>
      </ng-container>
    </section>
  </ng-template>
  