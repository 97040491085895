import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { VoicePortStatus } from '../../class/fdalib-port-status-list';

@Component({
  selector: 'fdalib-rj11-icon',
  templateUrl: './fdalib-rj11-icon.component.html',
  styleUrls: ['./fdalib-rj11-icon.component.scss']
})
export class FdalibRj11IconComponent implements OnInit {

  @Input() iconSelected: Observable<boolean>;
  @Input() iconLabel: string;
  @Input() set iconStatus(theIconStatus: VoicePortStatus) {
    this._iconStatus = theIconStatus;
    this.updateFromIconStatus();
  }
  @Input() iconHeight: Number = 80;
  @Input() iconWidth: Number = 80;

  // public _iconStatus: string;
  public _iconStatus: VoicePortStatus;
  public iconSize: string;
  public iconDisabled: Boolean;
  public iconNotify: Boolean; // new
  public iconMinus: Boolean;
  public iconNotYours: Boolean; // new


  public viewBox: string;
  public iconTitle: string = '';
  public iconScale: Number;

  constructor() {
    this._iconStatus = VoicePortStatus.Loading;
    if (!this.iconLabel)
    {
      this.iconLabel = 'Voice';
    }
  }

  ngOnInit() {
    this.updateFromIconStatus();
  }

  updateFromIconStatus() {
    //
    // Use _iconStatus to work out the rest of the icon's variables
    //
    this.viewBox = "-30 -30 60 60";

    this.iconDisabled = false;
    this.iconMinus = false;

    switch  (this._iconStatus)
    {
      case VoicePortStatus.Provisioned:
        this.iconSize = 'large';
        this.iconNotify = true;
        break;
      case VoicePortStatus.ProvisionedNotConnected:
        this.iconSize = 'large';
        this.iconNotify = true;
        break;
      case VoicePortStatus.NotYourService:
        this.iconSize = 'large';
        this.iconNotYours = true;
        break;
      case VoicePortStatus.NotProvisioned:
        this.iconSize = 'small';
        this.iconDisabled = true;
        break;
      case VoicePortStatus.NotTestable:
        this.iconSize = 'small';
        this.iconMinus = true;
        break;
      case VoicePortStatus.Loading: // Fall through
      default:
        this._iconStatus = VoicePortStatus.Loading;
        this.iconSize = 'small';
        break;
    }

    switch (this.iconSize)
    {
      case 'small':
        this.iconScale = 1.2;
        break;
      case 'medium':
        this.iconScale = 1.5;
        break;
      case 'large': // Fall through
      default:
        this.iconScale = 2;
        break;
    }

    this.iconTitle = this._iconStatus.replace(/-/g, ' ');
  }
}
