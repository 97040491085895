<h2 class="voice-port-status-badge {{_status}} pt-2 pb-2" title="{{badgeTitle | titlecase}}"><span>{{_status | portStatus}}</span></h2>

<section class="voice-port-status-key">

  <section class="status-key-item provisioning-status">
    <span class="key-icon">
      <fdalib-rj11-icon [iconStatus]="_status" iconWidth="50" iconHeight="50"></fdalib-rj11-icon>
    </span>
    <span class="key-label">
      <ng-container *ngIf="provisioningStatus ">
        {{provisioningStatus | statusToLabel}}<br>
      </ng-container>
      <ng-container *ngIf="serviceStatus">
        {{serviceStatus | statusToLabel}}
      </ng-container>
    </span>
  </section>

</section>
