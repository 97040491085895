import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { delay, map, tap, catchError } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-fda-top-nav',
  templateUrl: './fda-top-nav.component.html',
  styleUrls: ['./fda-top-nav.component.scss']
})
export class FdaLoginStatusComponent implements OnInit {

  public is_logged_in = false;
  public referrer: string = '';
  public login_hint: string = '';
  public referrer_base_url : string = '';
  public env = environment;
  public userData: Observable<any> = this.oidcSecurityService.userData$;

  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit() {

    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthorized) => {
      // console.error('FdaLoginStatusComponent ngOnInit isAuthorized: ', isAuthorized);
      this.is_logged_in = isAuthorized;
    });
  }

  //
  // See: https://github.com/damienbod/angular-auth-oidc-client (samples)
  //
  onOidcLoginClick(event: any) {
    event.preventDefault();

    //
    // Add login_hint (if any) to customParams in auth request to FIS.
    //
    let custom_params = {
      customParams: {},
    };
    const login_hint_param = sessionStorage.getItem('login_hint');
    if (login_hint_param) {
      custom_params.customParams['login_hint'] = login_hint_param;
    }
    this.oidcSecurityService.authorize(custom_params);
  }
  
  onOidcLogoffClick(event: any) {
    event.preventDefault();
    this.oidcSecurityService.logoff();
  }
  
  onOidcLogoffAndRevokeTokensClick(event: any) {
    console.log('onOidcLogoffAndRevokeTokensClick');
    event.preventDefault();
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe((response) => {
      console.log('logoffAndRevokeTokens response', response);
    });
  }
}
