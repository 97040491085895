<ng-container *ngIf="hourlyHistory">
    <section class="ont-state-history-hourly">
      <ul>
        <li *ngFor="let thisHour of hourlyHistory; index as i;" class="ont-hourly-state {{thisHour._status}} {{thisHour.intervalStart == theSelectedHour?.intervalStart ? 'selected' : ''}}" [attr.data-index]="i" (click)="onHourClick(thisHour)" title="{{thisHour.intervalStartMoment.format('hA')}} {{thisHour.statusName}}">
          {{thisHour.intervalStartMoment.format('h')}}<br>
          <span class="hour">{{thisHour.intervalStartMoment.format('A')}}</span>
        </li>
      </ul>
    </section> <!-- ont-state-history-hourly -->
  </ng-container>
  
  
