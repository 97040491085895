<h4>Northpower Fibre Diagnostics Application</h4>

<ng-container *ngIf="is_logged_in; else notLoggedIn">
    <p>Welcome to Northpower FDA.</p>

    <p>You can:</p>
    <ul>
        <li><a routerLink="/list_faults">Show a List of Customer Faults</a></li>
    </ul>
</ng-container>

<ng-template #notLoggedIn>
    <p>Please log in to start.</p>
</ng-template>
