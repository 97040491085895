import { Component, OnInit, Input, HostListener } from '@angular/core';
// import { IconButtonComponent } from '../icon-button/icon-button.component';
// import { IconSymbolComponent } from '../icon-symbol/icon-symbol.component';
import { Observable, Subject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// import { DataPortStatusBadgeComponent } from '../data-port-status-badge/data-port-status-badge.component';
// import { VoicePortStatusBadgeComponent } from '../voice-port-status-badge/voice-port-status-badge.component';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';
import { FdaApiService } from 'src/app/service/fda-api.service';
// import * as moment from 'moment-timezone';

@Component({
  selector: 'fdalib-port-diagnostics',
  templateUrl: './fdalib-port-diagnostics.component.html',
  styleUrls: ['./fdalib-port-diagnostics.component.scss']
})
export class FdalibPortDiagnosticsComponent implements OnInit {

  @Input() portDiagnostics: Observable<Object>;

  // public exploringTraffic: boolean = false;
  // public trafficSubject: Subject<any> = null;
  // public trafficHeaders: Observable<any> = null;

  // public allTrafficHeaders: Array<object> = [];
  // public trafficHeadersSubscription = null;
  
  constructor(private fdaLog: FdalibLoggerService) {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}








