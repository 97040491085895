import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fda-find-ont',
  templateUrl: './fda-find-ont.component.html',
  styleUrls: ['./fda-find-ont.component.scss']
})
export class FdaFindOntComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
