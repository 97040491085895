import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fdalib-fault-status-badge',
  templateUrl: './fdalib-fault-status-badge.component.html',
  styleUrls: ['./fdalib-fault-status-badge.component.scss']
})
export class FdalibFaultStatusBadgeComponent implements OnInit {

  _faultStatus: string; // Jira Fault Statuses: In Progress, Escalated, Waiting For Layer 2 Acceptance, etc.
  statusClass: string;  // Jira Fault Statuses: In Progress, Escalated, Waiting For Layer 2 Acceptance, etc.

  @Input() set faultStatus(value: string) {
      this._faultStatus = value;
      this.statusClass = value.toLowerCase().replace(/ /g, '-');
  }
  get faultStatus(): string {
    return this._faultStatus;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
