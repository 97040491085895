<svg xmlns="http://www.w3.org/2000/svg" viewBox="-45 -12 90 30"
  [ngClass]="_colorStyle"
  [attr.width]="iconWidth" [attr.height]="iconHeight"
  >
  <title>{{iconTitle}}</title>

  <g class="animated-logo">

    <circle id="en1" class="en" x="0" y="0" r="10" fill="gray" stroke="none" transform="translate(-25,0)" />
    <circle id="en2" class="en" x="0" y="0" r="10" fill="gray" stroke="none" transform="translate(  0,0)" />
    <circle id="en3" class="en" x="0" y="0" r="10" fill="gray" stroke="none" transform="translate( 25,0)" />

  </g>

</svg>


