import { Component, OnInit, Input } from '@angular/core';
import { DataPortStatus } from '../../class/fdalib-port-status-list';

@Component({
  selector: 'fdalib-data-port-status-badge',
  templateUrl: './fdalib-data-port-status-badge.component.html',
  styleUrls: ['./fdalib-data-port-status-badge.component.scss']
})
export class FdalibDataPortStatusBadgeComponent implements OnInit {

  @Input() set status(theStatus: DataPortStatus) {
    this._status = theStatus;
    this.updateFromStatus();
  }

  public _status: DataPortStatus;
  public badgeTitle: string = '';

  public deviceStatus: string;
  public provisioningStatus: string;
  public serviceStatus: string;

  constructor() {}

  ngOnInit() {
    this.updateFromStatus();
  }

  updateFromStatus() {
    this.badgeTitle = this._status.replace(/-/g, ' ');

    switch (this._status)
    {
      case DataPortStatus.ProvisionedDeviceDetected:
        this.deviceStatus       = 'device-detected';
        this.provisioningStatus = 'port-provisioned';
        this.serviceStatus      = 'in-service';
        break;
      case DataPortStatus.ProvisionedNoDevice:
        this.deviceStatus       = 'no-device';
        this.provisioningStatus = 'port-provisioned';
        this.serviceStatus      = '';
        break;
      case DataPortStatus.ProvisionedDegradedService:
        this.deviceStatus       = 'device-detected';
        this.provisioningStatus = 'port-provisioned';
        this.serviceStatus      = 'degraded-service';
        break;
      case DataPortStatus.NotYourServiceDeviceDetected:
        this.deviceStatus       = 'device-detected';
        this.provisioningStatus = 'provisioned-to-another-provider';
        this.serviceStatus      = '';
        break;
      case DataPortStatus.NotYourServiceNoDevice:
        this.deviceStatus       = 'no-device';
        this.provisioningStatus = 'provisioned-to-another-provider';
        this.serviceStatus      = '';
        break;
      case DataPortStatus.NotProvisionedDeviceDetected:
        this.deviceStatus       = 'device-detected';
        this.provisioningStatus = 'not-provisioned';
        this.serviceStatus      = '';
        break;
      case DataPortStatus.NotProvisionedNoDevice:
        this.deviceStatus       = 'no-device';
        this.provisioningStatus = 'not-provisioned';
        this.serviceStatus      = '';
        break;
      case DataPortStatus.Loading:
        this.deviceStatus       = '';
        this.provisioningStatus = '';
        this.serviceStatus      = '';
        break;
      case DataPortStatus.NotTestable:
        this.deviceStatus       = 'not-testable';
        this.provisioningStatus = 'not-testable';
        this.serviceStatus      = '';
        break;
    }

  }

}
