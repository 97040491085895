import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ONTStatusClass } from '../../class/fdalib-ont-status-class';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';

@Component({
  selector: 'fdalib-ont-hourly-history',
  templateUrl: './fdalib-ont-hourly-history.component.html',
  styleUrls: ['./fdalib-ont-hourly-history.component.scss']
})
export class FdalibOntHourlyHistoryComponent implements OnInit {

  public theSelectedHour: ONTStatusClass;

  @Input() hourlyHistory: ONTStatusClass[];
  @Output() selectedHour: EventEmitter<ONTStatusClass> = new EventEmitter<ONTStatusClass>();

  constructor(private fdalibLogger: FdalibLoggerService) { }

  ngOnInit() {
  }

  public onHourClick(hour: ONTStatusClass)
  {
    this.fdalibLogger.debug('onHourClick got hour', hour);
    this.theSelectedHour = hour;
    this.selectedHour.next(hour);
  }

}

