import { Component, OnInit, Input } from '@angular/core';
// import { ONTStatusClass } from '../ont-status-class';
import { OntHistoryEvent } from '../../class/fdalib-ont-history-event';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';
import { BehaviorSubject } from 'rxjs';
// import { map, mergeMap, shareReplay, delay, catchError } from 'rxjs/operators';

@Component({
  selector: 'fdalib-ont-hourly-history-details',
  templateUrl: './fdalib-ont-hourly-history-details.component.html',
  styleUrls: ['./fdalib-ont-hourly-history-details.component.scss']
})
export class FdalibOntHourlyHistoryDetailsComponent implements OnInit {

  @Input() allInHourEvents: OntHistoryEvent[];

  public selectedEvent: BehaviorSubject<OntHistoryEvent> = new BehaviorSubject(null);
  public eventCount: number = null;

  constructor(private fdaLog: FdalibLoggerService) { }

  ngOnInit() {
    // this.fdaLog.debug('OntHourlyHistoryDetailsComponent ngOnInit allInHourEvents', this.allInHourEvents);
    this.eventCount = this.allInHourEvents.length;
    this.fdaLog.debug('OntHourlyHistoryDetailsComponent ngOnInit eventCount', this.eventCount);

    if (this.eventCount == 1)
    {
      this.fdaLog.debug('OKOK eventCount was 1 clicking onInHourEventClick');
      this.onInHourEventClick(this.allInHourEvents[0]); // if there's only one event then select it
    }
  }

  public onInHourEventClick(event: OntHistoryEvent)
  {
    this.fdaLog.debug('onInHourEventClick event', event);
    this.selectedEvent.next(event);

  }
}
