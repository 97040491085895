<ng-container *ngIf="eventCount > 0; else noAllInHourEvents">

    <ng-container *ngIf="eventCount > 1">
      <section class="ont-state-history-in-hour">
        <ul>
          <ng-container *ngFor="let thisEvent of allInHourEvents; index as i;" >
            <li class="ont-in-hour-state {{thisEvent.event.status}} duration-{{thisEvent.eventDuration}}" (click)="onInHourEventClick(thisEvent)">
              <span *ngIf="i >= 0" class="float-left">
                  {{thisEvent.intervalStartMoment.format('h:mm')}}<br>
                  {{thisEvent.intervalStartMoment.format('A')}}
              </span>
              <span class="in-hour-status">{{thisEvent.event.ontStatus}}</span>
              <span *ngIf="i == allInHourEvents.length - 1" class="float-right">
                  {{thisEvent.intervalEndMoment.format('h:mm')}}<br>
                  {{thisEvent.intervalEndMoment.format('A')}}
              </span>
            </li>
          </ng-container>
        </ul>
      </section> <!-- ont-state-history-in-hour -->
    </ng-container>
  
  
    <ng-container *ngIf="(selectedEvent | async) as theSelectedEvent">
      <section class="ont-hourly-history-details">
        <ul>
          <li class="ont-state-name {{theSelectedEvent.event.status}}">
            <span class="event-time float-left">
              {{theSelectedEvent.intervalStartMoment.format('h:mm')}}<br>
              {{theSelectedEvent.intervalStartMoment.format('A')}}
            </span>
            {{theSelectedEvent.event.statusName}}
            <span class="event-time float-right">
              {{theSelectedEvent.intervalEndMoment.format('h:mm')}}<br>
              {{theSelectedEvent.intervalEndMoment.format('A')}}
            </span>
          </li>
          <li class="ont-state-description">{{theSelectedEvent.event.ontStatusSummary}}</li>
        </ul>
      </section> <!-- ont-hourly-history-details -->
    </ng-container>
  
  </ng-container>
  
  <ng-template #noAllInHourEvents>
    <section class="loading-label">
      Loading data for selected hour...
      <fdalib-dot-dot-dot colorStyle="regular"></fdalib-dot-dot-dot>
    </section>
  </ng-template>
