// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'test',
  security_token_server: "https://fis-test.northpower.com", // example standalone
  fdaapi_server: "https://fda-test.northpower.com",
  fda_client_id: "fibre-oss-bss",
  ont_diagnostics_daily_history_days: 14,
  low_traffic_rate_threshold: 0,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
