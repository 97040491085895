import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { FdalibLoggerService } from '../../service/fdalib-logger.service';
import { PortStatusList } from '../../class/fdalib-port-status-list';

@Component({
  selector: 'fdalib-port-selector',
  templateUrl: './fdalib-port-selector.component.html',
  styleUrls: ['./fdalib-port-selector.component.scss']
})
export class FdalibPortSelectorComponent implements OnInit {

  public validPorts: Array<String> = ['d1', 'd2', 'd3', 'd4', 'v1', 'v2'];
  public portStatusSubscription;

  public portStatusesLoading: Boolean;

  public d1Selected: BehaviorSubject<boolean>;
  public d2Selected: BehaviorSubject<boolean>;
  public d3Selected: BehaviorSubject<boolean>;
  public d4Selected: BehaviorSubject<boolean>;
  public v1Selected: BehaviorSubject<boolean>;
  public v2Selected: BehaviorSubject<boolean>;

  public d1Status: String;
  public d2Status: String;
  public d3Status: String;
  public d4Status: String;
  public v1Status: String;
  public v2Status: String;

  @Input() theSelectedPort: String;
  @Input() portStatuses: Observable<PortStatusList>;
  @Output() portSelection: EventEmitter<String> = new EventEmitter<String>();

  constructor(private fdaLog: FdalibLoggerService) {
    this.portStatusesLoading = true;
  }

  ngOnInit() {

    if (this.validPorts.indexOf(this.theSelectedPort) == -1) {
      // We were given an invalid port. Select the first port in the list.
      this.theSelectedPort = this.validPorts[0];
    }

    this.d1Selected = new BehaviorSubject(false);
    this.d2Selected = new BehaviorSubject(false);
    this.d3Selected = new BehaviorSubject(false);
    this.d4Selected = new BehaviorSubject(false);
    this.v1Selected = new BehaviorSubject(false);
    this.v2Selected = new BehaviorSubject(false);

    this.onPortSelected(this.theSelectedPort); // emit initial value

    this.portStatusSubscription = this.portStatuses.subscribe(portStatusList => {
      // this.fdaLog.debug('PortSelectorComponent portStatusSubscription portStatusList', portStatusList);
      this.d1Status = portStatusList.getDataPortStatus('d1');
      this.d2Status = portStatusList.getDataPortStatus('d2');
      this.d3Status = portStatusList.getDataPortStatus('d3');
      this.d4Status = portStatusList.getDataPortStatus('d4');
      this.v1Status = portStatusList.getVoicePortStatus('v1');
      this.v2Status = portStatusList.getVoicePortStatus('v2');

      const portsWithProvisionedService = portStatusList.getPortsWithProvisionedService();
this.fdaLog.debug('PortSelectorComponent portStatusSubscription portsWithProvisionedService', portsWithProvisionedService);
      const portsWithOwnedService = portStatusList.getPortsWithOwnedService();
this.fdaLog.debug('PortSelectorComponent portStatusSubscription portsWithOwnedService', portsWithOwnedService);
      const portsWithDevice = portStatusList.getPortsWithDeviceDetected();
this.fdaLog.debug('PortSelectorComponent portStatusSubscription portsWithDevice', portsWithDevice);
      if (portsWithProvisionedService.length > 0)
      {
        let newlySelectedPort = portsWithProvisionedService[0]; // select the first provisioned port

        if (portsWithOwnedService.length > 0)
        {
          newlySelectedPort = portsWithOwnedService[0]; // prefer the first owned port to just the first one provisioned

          if (portsWithProvisionedService.length == 1 && portsWithDevice.length > 0)
          {
            //
            // If only one RSP has services on this ONT and there is a port with a device detected
            // select the first port with a device detected instead of the first owned port.
            // This helps the "device in wrong port" scenario by selecting the port to which the device is wrongly connected.
            //
            newlySelectedPort = portsWithDevice[0];
          }
        }
        if (this.theSelectedPort != newlySelectedPort)
        {
          this.theSelectedPort = newlySelectedPort; 
          this.onPortSelected(this.theSelectedPort); // emit active port

        }
      }
      this.portStatusesLoading = false;
    });

  }

  ngOnDestroy() {
    this.portStatusSubscription.unsubscribe();
  }
  
  public onPortSelected(port: String) {
  this.theSelectedPort = port;
    this.portSelection.next(port);

    this.d1Selected.next(port == 'd1');
    this.d2Selected.next(port == 'd2');
    this.d3Selected.next(port == 'd3');
    this.d4Selected.next(port == 'd4');
    this.v1Selected.next(port == 'v1');
    this.v2Selected.next(port == 'v2');
  }

}
