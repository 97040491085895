import { Component, OnInit } from '@angular/core';
import { FdaApiService } from 'src/app/service/fda-api.service';
import { FdalibLoggerService } from '@northpower/fda-shared-lib';

import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, mergeMap, shareReplay, catchError, tap } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment';
import { PortStatusList, DataPortStatus, VoicePortStatus } from '@northpower/fda-shared-lib';
import { ONTStatusClass, ONTStatusEnum, ONTStatusJson } from '@northpower/fda-shared-lib';
import { OntHistoryEvent } from '@northpower/fda-shared-lib';
import { ONTDiagnosisClass, ONTDiagnosisEnum, ONTFaultJson, ONTHistoricAlarmJson } from '@northpower/fda-shared-lib';

// import * as moment from 'moment-timezoe';
import  moment from 'moment-es6';
// import { ONTStatusJson } from 'projects/shared-lib/src';

const LOW_LIGHT_LEVEL = -30;

@Component({
  selector: 'app-fda-ont-diagnostics',
  templateUrl: './fda-ont-diagnostics.component.html',
  styleUrls: ['./fda-ont-diagnostics.component.scss']
})
export class FdaOntDiagnosticsComponent implements OnInit {

  public queriedServices: Observable<any>;
  public queriedServicesLoading: boolean = false;
  public ontDiagnostics: Observable<string | any>;
  public ontAddress: Observable<any>;
  public diagnosticsPortStatuses: Observable<PortStatusList>;
  public ontStateCurrent: Observable<ONTStatusClass>;
  public ontDiagnosisCurrent: Observable<ONTDiagnosisClass>;
  public ontStateHistoryDaily: Observable<ONTStatusClass[]>;
  public ontStateHistoryHourly: Observable<ONTStatusClass[]>;
  public ontStateHistoryAllInHour: Observable<OntHistoryEvent[]>;
  public queriedPlace: Observable<any>;
  public servicesAddress: Observable<string>;
  public ontFaultHistory: Observable<ONTFaultJson[]>;
  public ontHistoricAlarms: Observable<ONTHistoricAlarmJson[]>;
  // public isLowRobustness: Observable<boolean>;

  public faultModalForm: FormGroup;
  public faultModalFormStatus: string = '';

  public hourlyHistoryLoading: boolean = false;
  public allInHourHistoryLoading: boolean = false;
  public allInHourHistoryValid: boolean = false;

  public fsl_instance: string = null;
  public inProgressFaultCount: number = 0;

  // public fsid: string = null;
  public env = environment;
  public scenario: string = null; // Fake ONT Diagnostic Test Scenario: Non-production environments only

  public selectedPort: BehaviorSubject<string>;
  public selectedPortConfigDetails: Observable<Object>;
  public selectedPortDiagnostics: Observable<Object>;

  public selectedDay: Subject<ONTStatusClass>;
  public selectedHour: Subject<ONTStatusClass>;

  public dataPortList:  Array<string> = ['d1','d2','d3','d4'];
  public voicePortList: Array<string> = ['v1','v2'];
  
  // test only
  public ontDiagnosticsScenario;
  public queryServicesScenario;
  public ontStateScenario;
  // private notTestedJson: ONTStatusJson = {
  //   "ontStatus": "Not Tested",
  //   "ontStatusDate": "2020-12-11T13:05:28+13:00",
  //   "intervalStart": "2021-01-28T12:31:40+13:00",
  //   "ontStatusDescription": "Green is good",
  //   "ontStatusSummary": "Test Test Test from fda-ont-diagnostics-conpinent.ts",
  //   "ontStatusColourName": "Green",
  //   "ontStatusColourHex": "#05DEFF",
  //   "ontStatusListOrder": "6",
  //   "ontStatusSeverity": "Cleared",
  //   "ontStatusType": "ONT",
  //   "method": "last known ONT state"
  // };
  // public ontStatusNotTested1 = new ONTStatusClass(this.notTestedJson);
  // public ontStatusNotTested2 = ONTStatusClass.createFromString('rebooting');
  // end test only

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private fdaApi: FdaApiService,
    private fdaLog: FdalibLoggerService,
    private modalService: NgbModal) { }

  ngOnInit()
  {
    this.fsl_instance = this.activatedRoute.snapshot.paramMap.get('fsl_instance_id');

    if (!this.fsl_instance)
    {
      this.fdaLog.error('You need to provide fsl_instance_id as query parameter.', 'For example try fsl_instance=233628-01');
      this.queriedServices = of('NoData');
      return;
    }

    console.log('ont-diagnostics ngOnInit calling fdaApi.getQueryServices');
    let queryServiceParams: any = {
      // include_other_rsps: 'yes', // only supported in B2B
      // fsl: null,
      // fsid: null,
      fsl_instance: this.fsl_instance,
    };

    this.queriedServicesLoading = true;
    this.queriedServices = this.fdaApi.getQueryServices(queryServiceParams).pipe(
      catchError(err => of('NoData')), // We need this to switch off dot dot dot.
      map(services => {
        console.log('ont-diagnostics ngOnInit calling getQueryServices pipe map services: ', {services});
        this.queriedServicesLoading = false;
        if (services != 'NoData')
        {
          services['mostRecentChange'] = this.getMostRecentProvisioningChange(services);

          const {
            fslInstanceId,
            firstProvisionedBrand,
            firstProvisionedServiceId,
            firstProvisionedDataCircuitId,
            firstProvisionedVoiceCircuitId
          } = this.getFirstProvisionedServices(services);

          services['firstProvisionedBrand'] = firstProvisionedBrand;
          services['firstProvisionedServiceId'] = firstProvisionedServiceId;
          services['firstProvisionedDataCircuitId'] = firstProvisionedDataCircuitId;
          services['firstProvisionedVoiceCircuitId'] = firstProvisionedVoiceCircuitId;

        }
        return services;
      }),
    );
    this.selectedPort = new BehaviorSubject(this.dataPortList[0]); // Select the first data port by default

    this.selectedDay = new Subject();
    this.selectedHour = new Subject();

    this.selectedPortConfigDetails = combineLatest(this.queriedServices, this.selectedPort).pipe(
      map(results => this.portDetailsFromServiceAndPort(results[0], results[1]))
    );

    const notTestable: ONTStatusClass = ONTStatusClass.createFromString('not-testable');
    let stateCurrentParams: any = {
      fslInstanceId: this.fsl_instance,
    };
    this.ontStateCurrent = this.fdaApi.getONTStateCurrentWithRefresh(stateCurrentParams).pipe(
      catchError(err => of(notTestable)),
      map((theONTStatusJson: any) => {
        const theONTStatusClass: ONTStatusClass = new ONTStatusClass(theONTStatusJson);
        return theONTStatusClass;
      })
    );

    this.ontDiagnostics = this.queriedServices.pipe(
      mergeMap(theQueriedServices => {
        console.log('queriedServices mergeMap for ontDiagnostics');
        if (theQueriedServices == 'NoData' || !theQueriedServices.ONTServices || theQueriedServices.ONTServices.length == 0)
        {
          console.log('queriedServices mergeMap for ontDiagnostics NoData - bailing');
          return of(['NoData']);
        }
        let getParams: any = {'fslInstanceId': theQueriedServices.ONTServices[0].fslInstanceIdentifier};
        if (!this.env.production && this.ontDiagnosticsScenario)
        {getParams.scenario = this.ontDiagnosticsScenario;
        }
        console.log('queriedServices mergeMap for ontDiagnostics calling getONTDiagnosticsWithRefresh');
        let result = this.fdaApi.getONTDiagnosticsWithRefresh(getParams).pipe(
          catchError(err => of('NoData')),
          map(ontDiagnostics => {
            console.log('getONTDiagnosticsWithRefresh map got ontDiagnostics', {ontDiagnostics});
            this.fdaApi.refreshONTStateCurrent(); // Re-fetch current ONT state in case getONTDiagnostics has changed it.
            this.fdaApi.refreshONTStateHistoryDaily(); // Re-fetch daily ONT state history in case getONTDiagnostics has changed it.
            return ontDiagnostics;
          }),
        );
        return result;
      }),
      shareReplay(1)
    );

    this.ontAddress = this.fdaApi.getAddressByAddressIdentity(this.fsl_instance).pipe(
      tap(theOntAddress => {
        //console.log('ontAddress pipe tap', theOntAddress);
      }),
    );

    this.diagnosticsPortStatuses = combineLatest(this.queriedServices, this.ontDiagnostics).pipe(
      // catchError(err => of('NoData')),
      map(results => {
        // this.fdaLog.debug('diagnosticsPortStatuses in map results[1]', results[1]);
        const thePortStatuses = this.portStatusListFromServicesAndDiagnostics(results[0], results[1]);
        return thePortStatuses;
      }),
    );

    this.selectedPortDiagnostics = combineLatest(this.queriedServices, this.selectedPort, this.ontDiagnostics).pipe(
      catchError(err => of('NoData')),
      map(results => {
        // this.fdaLog.debug('selectedPortDiagnostics in map results[2]', results[2]);
        return (this.isNoData(results)) ? 'NoData' : this.portDiagnosticsFromServicesPortAndDiagnostics(results[0], results[1], results[2]);
      })
    );

    // //
    // // Call queryPlace so we can show the address of this fsl
    // //
    // this.queriedPlace = this.queriedServices.pipe(
    //   mergeMap(theQueriedServices => {
    //     if (theQueriedServices == 'NoData')
    //     {
    //       return of('NoData');
    //     }
    //     console.log('queriedServices mergeMap for getQueryPlace', {theQueriedServices});
    //     const getParams: any = {'fsl': theQueriedServices.fibreServiceLocationIdentifier};
    //     // console.log('queriedPlace in mergeMap getParams', getParams);
    //     return this.fdaApi.getQueryPlace(getParams).pipe(
    //       catchError(err => of('NoData')),
    //     );
    //   }),
    //   shareReplay(1)
    // );

    this.servicesAddress = this.queriedServices.pipe(
      map(theQueriedServices => {
        console.log('finding service address using', {theQueriedServices});
        if (!theQueriedServices || theQueriedServices == 'NoData' || !theQueriedServices.ServiceLocation)
        {
          return '-- -- --';
        }
        let address: string = '';
        if (theQueriedServices.ServiceLocation.Address)
        {
          address = theQueriedServices.ServiceLocation.Address.addressString;
        }
        return address;
      })
    );

    this.ontStateHistoryDaily = this.queriedServices.pipe(
      mergeMap(theQueriedServices => {
        if (theQueriedServices == 'NoData' || !theQueriedServices.ONTServices || theQueriedServices.ONTServices.length == 0)
        {
          return of([]);
        }
        let stateHistoryParams: any = {
          fslInstanceId: theQueriedServices.ONTServices[0].fslInstanceIdentifier,
          numDaysHistory: this.env.ont_diagnostics_daily_history_days,
        };
        if (!this.env.production && this.ontStateScenario)
        {
          stateHistoryParams.scenario = this.ontStateScenario;
        }

        return this.fdaApi.getONTStateHistoryDailyWithRefresh(stateHistoryParams)
          .pipe(
            catchError(err => of([])),
            map<ONTStatusJson[], ONTStatusClass[]>(function(theONTStatusJsonArray: ONTStatusJson[]): ONTStatusClass[] {
              // console.log('IN getONTStateHistoryDaily piped mapped to theONTStatusJsonArray', theONTStatusJsonArray);
              return theONTStatusJsonArray.map((theONTStatusJson) => {
                return new ONTStatusClass(theONTStatusJson);
              });
            })
          );
      }),
      shareReplay(1)
    );

    this.ontFaultHistory = this.fdaApi.getONTFaultHistory(this.fsl_instance).pipe(
      tap((faultList: ONTFaultJson[]) => {
        const inProgressFaultsList: ONTFaultJson[] = faultList.filter((fault) => fault.isInProgress);
        // const inProgressFaultsList: ONTFaultJson[] = faultList.filter((fault) => fault.faultStatus == 'In Progress');
        this.inProgressFaultCount = inProgressFaultsList.length;
        // console.log('inProgressFaultCount', this.inProgressFaultCount);
      })
    );

    this.ontHistoricAlarms = this.fdaApi.getHistoricAlarms(this.fsl_instance).pipe(
      tap((historicAlarmsList: ONTHistoricAlarmJson[]) => {
        console.log('historicAlarmsList', historicAlarmsList);
      })
    );

    this.initStateHistoryHourly();

    this.initStateHistoryAllInHour();

    this.faultModalForm = new FormGroup({
      diagnosticsId: new FormControl(''),
      serviceId: new FormControl(''),
      dataCircuitId: new FormControl(''),
      voiceCircuitId: new FormControl(''),
      serviceAffected: new FormControl('', Validators.required),
    });

    this.faultModalForm.statusChanges.subscribe( (status) => {
      // this.fdaLog.debug('faultModalForm.statusChanges', status); // status will be "VALID", "INVALID", "PENDING" or "DISABLED"
      //
      // Keep track of invalid form controls so we can display an error label
      // next to the form submit button.
      //
      this.faultModalFormStatus = status;
    })

  }

  get serviceAffected() { return this.faultModalForm.get('serviceAffected'); }


  public onPortSelectorSelection(port)
  {
    this.selectedPort.next(port);
  }
  
  public onDailyHistorySelection(day: ONTStatusClass)
  {
    this.selectedDay.next(day);
  }
  
  public onHourlyHistorySelection(hour: ONTStatusClass)
  {
    this.selectedHour.next(hour);
  }
  
  public scrollToTarget(el: HTMLElement)
  {
    el.scrollIntoView({behavior: 'smooth'});
  }

  public onDiagnosticsRefresh(event: MouseEvent)
  {
    this.queriedServicesLoading = true;
    window.location.reload();
    // this.fdaApi.refreshONTDiagnostics();
  }
  
  public onLogOntFaultClick(diagnosticsId: string, theQueriedServices, modalTemplate)
  {
    if (!theQueriedServices.firstProvisionedDataCircuitId && !theQueriedServices.firstProvisionedVoiceCircuitId)
    {
      this.fdaLog.error('You do not own any services at this location');
      return;
    }
    if (theQueriedServices.firstProvisionedDataCircuitId && theQueriedServices.firstProvisionedVoiceCircuitId)
    {
      //
      // Both Data and Voice services exist. Ask user to select service.
      //
      this.faultModalForm.patchValue({diagnosticsId: diagnosticsId});
      this.faultModalForm.patchValue({brand: theQueriedServices.firstProvisionedBrand});
      this.faultModalForm.patchValue({serviceId: theQueriedServices.firstProvisionedServiceId});
      this.faultModalForm.patchValue({dataCircuitId: theQueriedServices.firstProvisionedDataCircuitId});
      this.faultModalForm.patchValue({voiceCircuitId: theQueriedServices.firstProvisionedVoiceCircuitId});
      this.modalService.open(modalTemplate).result.then((close_val) => {
      }, (dismiss_val) => {
      });
      return;
    }
    // Data service only (Voice service only does not happen)
    const brand = theQueriedServices.firstProvisionedBrand;
    const faultServiceId = theQueriedServices.firstProvisionedServiceId;
    const faultCircuitId = theQueriedServices.firstProvisionedDataCircuitId;
    this.router.navigate(['/faults_report', {
      fsl_instance: this.fsl_instance,
      diagnostics_id: diagnosticsId,
      brand: brand,
      service_affected: 'data',
      service_id: faultServiceId,
      circuit_id: faultCircuitId,
    }]);    
  }

  public onSubmitFaultModalForm(modal)
  {
    if (this.faultModalForm.valid)
    {
      modal.close();
      const diagnosticsId = this.faultModalForm.value.diagnosticsId;
      const brand = this.faultModalForm.value.brand;
      const serviceAffected = this.faultModalForm.value.serviceAffected;
      const faultServiceId = this.faultModalForm.value.serviceId;
      let faultCircuitId = '';
      if (serviceAffected == 'data')
      {
        faultCircuitId = this.faultModalForm.value.dataCircuitId;
      }
      if (serviceAffected == 'voice')
      {
        faultCircuitId = this.faultModalForm.value.voiceCircuitId;
      }
      this.router.navigate(['/faults_report', {
        fsl_instance: this.fsl_instance,
        diagnostics_id: diagnosticsId,
        brand: brand,
        service_affected: serviceAffected,
        service_id: faultServiceId,
        circuit_id: faultCircuitId,
      }]);
    }
    else
    {
      this.touchFormControls();
    }
  }

  public onFaultHistoryClick(theFault: ONTFaultJson)
  {
      // this.sspaLogger.debug('onFaultHistoryClick', {theFault});
      // if (theFault.b2bReference)
      // {
      //   //
      //   // Redirect back to SSP fault details page.
      //   //
      //   const sspFaultDetailsUrl = this.env.ssp_base_url + '/v1-2-0/faults_item/111892';
      //   window.location.href = sspFaultDetailsUrl;
      // }
  }

  public touchFormControls() {
    const controls = this.faultModalForm.controls;
    for (const name in controls) {
        controls[name].markAsTouched();
    }
  }

  private initStateHistoryHourly()
  {
    this.ontStateHistoryHourly = combineLatest(this.queriedServices, this.selectedDay).pipe(
      mergeMap<any, Observable<ONTStatusClass[]>>(results => {
        this.hourlyHistoryLoading = true;
        this.allInHourHistoryValid = false;
        const theQueriedServices = results[0];
        const theSelectedDay: ONTStatusClass = results[1];

        if (theQueriedServices == 'NoData' || !theQueriedServices.ONTServices || theQueriedServices.ONTServices.length == 0)
        {
          return of([]);
        }
        const hourlyData: Observable<ONTStatusClass[]> = this.fdaApi.getONTStateHistoryHourly({'fslInstanceId': theQueriedServices.ONTServices[0].fslInstanceIdentifier, 'date': theSelectedDay.intervalStartMoment.format('YYYY-MM-DD') })
          .pipe(
            catchError(err => of([])),
            map<ONTStatusJson[], ONTStatusClass[]>((theONTStatusJsonArray: ONTStatusJson[]): ONTStatusClass[] => {
              this.hourlyHistoryLoading = false;
              return theONTStatusJsonArray.map((theONTStatusJson) => {
                return new ONTStatusClass(theONTStatusJson);
              });
            })
          );
        return hourlyData;
      }),
      shareReplay(1)
    );
  }
  
  private initStateHistoryAllInHour()
  {
    this.ontStateHistoryAllInHour = combineLatest(this.queriedServices, this.selectedHour).pipe(
      mergeMap<any, Observable<OntHistoryEvent[]>>(results => {
        this.allInHourHistoryLoading = true;
        const theQueriedServices = results[0];
        const theSelectedHour: ONTStatusClass = results[1];


        if (theQueriedServices == 'NoData' || !theQueriedServices.ONTServices || theQueriedServices.ONTServices.length == 0)
        {
          return of([]);
        }
        const formattedStartOfHour = theSelectedHour.intervalStartMoment.format(); // ISO 8601

        const allInHourData: Observable<OntHistoryEvent[]> = this.fdaApi.getONTStateHistoryAllInHour({
          'fslInstanceId': theQueriedServices.ONTServices[0].fslInstanceIdentifier,
           'date': encodeURIComponent(formattedStartOfHour), // ISO 8601 date encoded with '+' -> '%2B', ':' -> '%253A'
          })
          .pipe(
            catchError(err => of([])),
            map<ONTStatusJson[], OntHistoryEvent[]>((theONTStatusJsonArray: ONTStatusJson[]): OntHistoryEvent[] => {
              this.allInHourHistoryLoading = false;
              this.allInHourHistoryValid = true;
              let previousOntHistoryEvent = null;
              const ontHistoryEventArray = theONTStatusJsonArray.map((theONTStatusJson, theIndex, theArray) => {
                const currentOntHistoryEvent = new OntHistoryEvent(theONTStatusJson);
                if (previousOntHistoryEvent)
                {
                  previousOntHistoryEvent.intervalEndMoment = moment(currentOntHistoryEvent.intervalStartMoment);
                }
                previousOntHistoryEvent = currentOntHistoryEvent;
                if (theIndex == (theArray.length - 1))
                {
                  // last item in theONTStatusJsonArray 
                  currentOntHistoryEvent.intervalEndMoment = moment(theSelectedHour.intervalStartMoment).add(1, 'hours');
                }
                return currentOntHistoryEvent;
              });
              return ontHistoryEventArray;
            })
          );
        return allInHourData;
      }),
      shareReplay(1)
    );
  }

  private getServiceAndCircuitFromSelectedPort(queriedService, selectedPort)
  {
    // this.fdaLog.debug('getServiceAndCircuitFromSelectedPort queriedService', queriedService);

    let i, j, k;
    for (i = 0; queriedService.ONTServices && i < queriedService.ONTServices.length; i++)
    {
      let servicesForThisFSL = queriedService.ONTServices[i];
      if (this.fsl_instance != null && servicesForThisFSL.fslInstanceIdentifier != this.fsl_instance)
      {
        // fsl instance mismatch - skip this one 
        continue;
      }

      for (j = 0; j < servicesForThisFSL.Services.length; j++)
      {
        let thisService = servicesForThisFSL.Services[j];
        // if (this.fsid != null && thisService.serviceIdentifier != this.fsid)
        // {
        //   // fsid mismatch - skip this one 
        //   continue;
        // }

        if (!['Active','Pending Relinquished','Pending Superseded'].includes(thisService.serviceStatus))
        {
          //
          // This is not a Active, Pending Relinquished or Pending Superseded service. Skip it.
          //
          continue;
        }
        for (k = 0; k < thisService.Circuits.length; k++)
        {
          let thisCircuit = thisService.Circuits[k];

          let circuitDescription = ''; // ends up as one of 'd1', 'd2', 'd3', 'd4', 'v1' or 'v2'
          if (thisCircuit.circuitType == 'Data')
          {
            circuitDescription += 'd';
            circuitDescription += thisCircuit.terminalPort;
          }
          else if (thisCircuit.circuitType == 'Voice')
          {
            circuitDescription += 'v';
            if (thisService.serviceDescription == 'Additional Voice Port')
            {
              circuitDescription += '2'; // Additional Voice is always on port 2.
            }
            else
            {
              circuitDescription += '1'; // 'Regular' voice is always on port 1.
            }
          }

          if (circuitDescription == selectedPort)
          {
            //
            // We've found a circuit that matches fsl, circuitType and terminalPort
            //
            return {
              circuit: thisCircuit,
              service: thisService,
            }
          }
        }
      }
    }
    // no matching circuit
    return null;
  }


  private getMostRecentProvisioningChange(queriedService)
  {
    let result = null;
    let i, j, k;
    for (i = 0; queriedService.ONTServices && i < queriedService.ONTServices.length; i++)
    {
      let servicesForThisFSL = queriedService.ONTServices[i];
      if (this.fsl_instance != null && servicesForThisFSL.fslInstanceIdentifier != this.fsl_instance)
      {
        // fsl instance mismatch - skip this one 
        continue;
      }

      for (j = 0; j < servicesForThisFSL.Services.length; j++) // services (lower-case in B2B)
      {
        let thisService = servicesForThisFSL.Services[j]; // services (lower-case in B2B)
        // if (this.fsid != null && thisService.serviceIdentifier != this.fsid)
        // {
        //   // fsid mismatch - skip this one 
        //   continue;
        // }
        if (thisService.dateModified)
        {
          const thisServiceDateModified = moment(thisService.dateModified);
          if (!result || result < thisServiceDateModified)
          {
            result = thisServiceDateModified; // This service was modified more recently than any we've seen so far.
          }
        }
      }
    }
    return result;
  }

  private getFirstProvisionedServices(queriedService)
  {
    //
    // ASSUMES this.fsl_instance is set.
    // Return value is first provisioned service from that fsl_instance.
    //
    let result = null;
    let i, j, k;
    let fslInstanceId = null;
    let brand = null;
    let serviceId = null;
    let dataCircuitId = null;
    let voiceCircuitId = null;

    for (i = 0; queriedService.ONTServices && i < queriedService.ONTServices.length && serviceId == null; i++)
    {
      let servicesForThisFSL = queriedService.ONTServices[i];
      if (this.fsl_instance != null && servicesForThisFSL.fslInstanceIdentifier != this.fsl_instance)
      {
        // fsl instance mismatch - skip this one 
        continue;
      }

      for (j = 0; j < servicesForThisFSL.Services.length && serviceId == null; j++)
      {
        let thisService = servicesForThisFSL.Services[j];
        // if (this.fsid != null && thisService.serviceIdentifier != this.fsid)
        // {
        //   // fsid mismatch - skip this one 
        //   continue;
        // }
        if (thisService.serviceActive != 'Yes')
        {
          // service is not provisioned - skip this one 
          continue;
        }

        // We've found a provisioned service
        fslInstanceId = servicesForThisFSL.fslInstanceIdentifier;
        serviceId = thisService.serviceIdentifier;
        brand = thisService.brand;

        //
        // Look for Data abd Voice circuit Ids.
        //
        for (k = 0; k < thisService.Circuits.length; k++)
        {
          let thisCircuit = thisService.Circuits[k];
          if (thisCircuit.circuitType == 'Data')
          {
            dataCircuitId = thisCircuit.circuitIdentifier;
          }
          if (thisCircuit.circuitType == 'Voice')
          {
            voiceCircuitId = thisCircuit.circuitIdentifier;
          }
        }
      }
    }
    return {
      fslInstanceId: fslInstanceId,
      firstProvisionedBrand: brand,
      firstProvisionedServiceId: serviceId,
      firstProvisionedDataCircuitId: dataCircuitId,
      firstProvisionedVoiceCircuitId: voiceCircuitId,
    };
  }



  private lowLightLevelFromDiagnostics(theONTDiagnostics) : boolean
  {
    let result: boolean = false;
    if (theONTDiagnostics.diagnosticsResult && theONTDiagnostics.diagnosticsResult.simple_diagnostics)
    {
      let lightLevel = theONTDiagnostics.diagnosticsResult.simple_diagnostics.olt_light_level.replace(' dBm', '');
      if (lightLevel == '')
      {
        result = true;
      }
      else if (lightLevel < LOW_LIGHT_LEVEL)
      {
        result = true;
      }
    }
    return result;        
  }

  private portStatusListFromServicesAndDiagnostics(theQueriedServices, theONTDiagnostics) : PortStatusList
  {
    let portStatusList = new PortStatusList();

    this.dataPortList.forEach((dataPort, index) => {
      const selectedServiceAndCircuit = this.getServiceAndCircuitFromSelectedPort(theQueriedServices, dataPort);
      const selectedSimpleDiagnostics = this.getSimpleDiagnosticsFromSelectedPort(theONTDiagnostics, dataPort);
      const selectedEthPortDiagnostics = this.getEthPortDiagnosticsFromSelectedPort(theONTDiagnostics, dataPort);

      const portStatus: DataPortStatus = this.dataPortStatusFromServiceAndDiagnostics(selectedServiceAndCircuit, selectedSimpleDiagnostics, selectedEthPortDiagnostics);
      portStatusList.setDataPortStatus(dataPort, portStatus);
    });

    this.voicePortList.forEach((voicePort, index) => {
      const selectedServiceAndCircuit = this.getServiceAndCircuitFromSelectedPort(theQueriedServices, voicePort);
      const selectedPotsPortDiagnostics = this.getPotsPortDiagnosticsFromSelectedPort(theONTDiagnostics, voicePort);

      const portStatus: VoicePortStatus = this.voicePortStatusFromServiceAndDiagnostics(selectedServiceAndCircuit, selectedPotsPortDiagnostics);
      portStatusList.setVoicePortStatus(voicePort, portStatus);
    });
    return portStatusList;        
  }

  private getSimpleDiagnosticsFromSelectedPort(ontDiagnostics, selectedPort)
  {
    let portStatus = '';
    if (ontDiagnostics && ontDiagnostics.diagnosticsResult && this.dataPortList.indexOf(selectedPort) > -1)
    {
      if (!ontDiagnostics.diagnosticsResult.simple_diagnostics || ontDiagnostics.diagnosticsResult.simple_diagnostics.olt_light_level == ' dBm')
      {
        //
        // Could not get ont diagnostics. Likely fibre is broken or ONT is powered off.
        //
        return null;
      }
      const dataPort = this.dataPortList.indexOf(selectedPort);
      if (ontDiagnostics.diagnosticsResult.simple_diagnostics.port_status)
      {
        const simpleDiagnostics = ontDiagnostics.diagnosticsResult.simple_diagnostics.port_status[dataPort];
        return simpleDiagnostics;
      }      
    }
    return null;
  }

  private getEthPortDiagnosticsFromSelectedPort(ontDiagnostics, selectedPort)
  {
    let portStatus = '';
    if (ontDiagnostics && ontDiagnostics.diagnosticsResult && this.dataPortList.indexOf(selectedPort) > -1)
    {
      const dataPort = this.dataPortList.indexOf(selectedPort);
      if (ontDiagnostics.diagnosticsResult.full_diagnostics.port_information && ontDiagnostics.diagnosticsResult.full_diagnostics.port_information.ethernet_ports)
      {
        const ethPortDiagnostics = ontDiagnostics.diagnosticsResult.full_diagnostics.port_information.ethernet_ports[dataPort];
        return ethPortDiagnostics;
      }
    }
    return null;
  }

  private getPotsPortDiagnosticsFromSelectedPort(ontDiagnostics, selectedPort)
  {
    let portStatus = '';
    // console.log('getPotsPortDiagnosticsFromSelectedPort ontDiagnostics', ontDiagnostics);
    if (ontDiagnostics && ontDiagnostics.diagnosticsResult && this.voicePortList.indexOf(selectedPort) > -1)
    {
      if (!ontDiagnostics.diagnosticsResult.simple_diagnostics || ontDiagnostics.diagnosticsResult.simple_diagnostics.olt_light_level == ' dBm')
      {
        //
        // Could not get ont diagnostics. Likely fibre is broken or ONT is powered off.
        //
        return null;
      }

      const voicePort = this.voicePortList.indexOf(selectedPort); // 0 or 1
      const potsPortDiagnostics = ontDiagnostics.diagnosticsResult.full_diagnostics.port_information.pots_ports[voicePort];

      //
      // Enrich the posts port diagnostics with SIP IP Host data
      //
      const sipIpHost = ontDiagnostics.diagnosticsResult.full_diagnostics.sip_ip_host;
      potsPortDiagnostics.sip_ip_host = sipIpHost;

      return potsPortDiagnostics;
    }
    return null;
  }

  private dataPortStatusFromServiceAndDiagnostics(selectedServiceAndCircuit, selectedSimpleDiagnostics, selectedEthPortDiagnostics) : DataPortStatus
  {
    let portStatus: DataPortStatus;
    // this.fdaLog.debug('dataPortStatusFromServiceAndDiagnostics selectedServiceAndCircuit', selectedServiceAndCircuit); 
    // this.fdaLog.debug('dataPortStatusFromServiceAndDiagnostics selectedSimpleDiagnostics', selectedSimpleDiagnostics); 
    if (selectedSimpleDiagnostics == null || selectedSimpleDiagnostics.olt_light_level == ' dBm')
    {
      // No diagnostics result or no OLT light level
      portStatus = DataPortStatus.NotTestable;
    }
    else if (selectedServiceAndCircuit && !selectedServiceAndCircuit.service.brand)
    {
      // Query Services didn't return a brand name for this service.
      // It must be owned by a different RSP.
      //
      if (selectedSimpleDiagnostics.eth_port_status == 'In Service')
      {
        portStatus = DataPortStatus.NotYourServiceDeviceDetected;
      }
      else
      {
        portStatus = DataPortStatus.NotYourServiceNoDevice;
      }
    }
    else if (selectedServiceAndCircuit == null || selectedServiceAndCircuit.service.serviceActive != 'Yes')
    {
      if (selectedSimpleDiagnostics.eth_port_status == 'In Service')
      {
        portStatus = DataPortStatus.NotProvisionedDeviceDetected;
      }
      else
      {
        portStatus = DataPortStatus.NotProvisionedNoDevice;
      }
    }
    // Assert: serviceActive == 'Yes'
    else if (selectedSimpleDiagnostics.eth_port_status == 'In Service')
    {
      if (this.isDegradedService(selectedServiceAndCircuit, selectedEthPortDiagnostics))
      {
        portStatus = DataPortStatus.ProvisionedDegradedService;
      }
      else
      {
        portStatus = DataPortStatus.ProvisionedDeviceDetected;
      }
    }
    else if (selectedSimpleDiagnostics.eth_port_status == 'Out Of Service')
    {
      portStatus = DataPortStatus.ProvisionedNoDevice;
    }
    return portStatus;
  }

  private isDegradedService(selectedServiceAndCircuit, selectedEthPortDiagnostics) : boolean
  {
    if (selectedEthPortDiagnostics.eth_port_duplex != 'full-duplex')
    {
      return true;
    }
    const current_link_speed: number = this.linkSpeedFromPortState(selectedEthPortDiagnostics.eth_port_current_port_state);
    const eir_bandwidth: number = this.eirBandwidthFromServiceDescription(selectedServiceAndCircuit.service.serviceDescription);

    if (current_link_speed < eir_bandwidth)
    {
      return true;
    }
    return false;
  }

  private linkSpeedFromPortState(portState: string) : number
  {
    //
    // Expect three words '<up|down> <speed> <duplex>' e.g. 'up 1g full-duplex'
    //
    const portStateWords: Array<string> = portState.split(' ');
    let portSpeedWord: string;
    let portSpeed: number;

    if (portState.toLowerCase() == 'down')
    {
      return 0;
    }

    if (portStateWords.length >= 3)
    {
      portSpeedWord = portStateWords[portStateWords.length - 2];
    }
    else
    {
      this.fdaLog.error('Unrecongised port state - Assuming 1G', portState);
      portSpeedWord = '1g'; // ASSUME: 1g if we can't read port speed
    }
    //
    // portSpeedWord is one of '10m', '100m' or '1g'
    //
    switch (portSpeedWord.toLowerCase())
    {
      case '1g':
        portSpeed = 1 * Math.pow(2, 30);
        break;
      case '100m':
        portSpeed = 100 * Math.pow(2, 20);
        break;
      case '10m':
        portSpeed = 10 * Math.pow(2, 20);
        break;
      default:
        this.fdaLog.error('Unrecongised port speed - Assuming 1G', portSpeed);
        portSpeed = 1 * Math.pow(2, 30);
        break;

    }
    return portSpeed;
  }
  
  private eirBandwidthFromServiceDescription(serviceDescription: string) : number
  {
    //
    // serviceDescription is e.g. 'BS3 100M/100M CIR2.5M/2.5M'.
    // We're looking to decode the second to last word.
    //
    const serviceDescriptionWords: Array<string> = serviceDescription.split(' ');
    let serviceSpeedWord: string;
    let serviceUpWord: string;
    let serviceSpeed: number;
    if (serviceDescriptionWords.length >= 3)
    {
      serviceSpeedWord = serviceDescriptionWords[serviceDescriptionWords.length - 2]; // E.g. '200M/200M'
    }
    else
    {
      this.fdaLog.error('Unrecognised service description - Assuming 200M/200M', serviceDescription);
      serviceSpeedWord = '200M/200M'; // ASSUME: 200M/200M if we can't read service speed
    }

    const serviceUpDownWords: Array<string> = serviceSpeedWord.split('/');
    if (serviceUpDownWords.length == 2)
    {
      serviceUpWord = serviceUpDownWords[0]; // E.g. '200M'
    }
    else
    {
      //
      // serviceDescription could be a different format like "Ministry of Education Equity Product Offer (BS2A 100M/20M)"
      // Try to decode using regexp.
      //
      var service_cir_regexp = /\S*CIR[\d\.]+M\/[\d\.]+M/; // Matches e.g. LCIR2.5M/2.5M
      var service_speed_regexp = /([\d\.]+[MG])\/[\d\.]+M/; // Matches e.g. 100M/20M or 1G/2.5M
      var service_no_cir = serviceDescription.replace(service_cir_regexp, ''); // Remove the CIR word
      var matchedServiceSpeed = service_no_cir.match(service_speed_regexp); // Match the speed word

      if (matchedServiceSpeed.length == 2) // E.g. ["100M/20M", "100M"]
      {
        serviceUpWord = matchedServiceSpeed[1]; // E.g. "100M"
      }
      else
      {
        this.fdaLog.error('Unrecognised service speed word - Assuming 200M/200M', serviceSpeedWord);
        serviceUpWord = '200M'; // ASSUME: 200M if we can't read speed word
      }
    }

    const lastSpeedLetter = serviceUpWord.slice(-1); // E.g. M or G
    const speedNumber: number = Number(serviceUpWord.slice(0, serviceUpWord.length - 1)); // E.g. 100

    //
    // lastSpeedLetter is one of 'M' or 'G'
    //
    switch (lastSpeedLetter.toLowerCase())
    {
      case 'g':
        serviceSpeed = speedNumber * Math.pow(2, 30);
        break;
      case 'm':
        serviceSpeed = speedNumber * Math.pow(2, 20);
        break;
      default:
      this.fdaLog.error('Unrecongised service speed - Assuming 200M', serviceUpWord);
        serviceSpeed = 200 * Math.pow(2, 20);
        break;

    }
    return serviceSpeed;
  }
  
  private voicePortStatusFromServiceAndDiagnostics(selectedServiceAndCircuit, selectedPotsPortDiagnostics) : VoicePortStatus
  {
    let portStatus: VoicePortStatus;

    if (selectedPotsPortDiagnostics == null)
    {
      portStatus = VoicePortStatus.NotTestable;
    }
    else if (selectedServiceAndCircuit && !selectedServiceAndCircuit.service.brand)
    {
      // Query Services didn't return a brand name for this service.
      // It must be owned by a different RSP.
      //
      portStatus = VoicePortStatus.NotYourService;
    }
    else if (selectedServiceAndCircuit == null || selectedServiceAndCircuit.service.serviceActive != 'Yes')
    {
      portStatus = VoicePortStatus.NotProvisioned;
    }
    // Assert serviceActive == 'Yes'
    else 
    {
      if (selectedPotsPortDiagnostics.pots_port_sip_service.service_status == 'registered')
      {
        portStatus = VoicePortStatus.Provisioned;
      }
      else
      {
        portStatus = VoicePortStatus.ProvisionedNotConnected;
      }
    }
    return portStatus;
  }

  private portDiagnosticsFromServicesPortAndDiagnostics(theQueriedServices, theSelectedPort, theONTDiagnostics)
  {
    // this.fdaLog.debug('portDiagnosticsFromServicesPortAndDiagnostics theONTDiagnostics', theONTDiagnostics); 
    if (!theONTDiagnostics || !theONTDiagnostics.diagnosticsResult || !theSelectedPort || !theONTDiagnostics)
    {
      return null;
    }

    const selectedServiceAndCircuit = this.getServiceAndCircuitFromSelectedPort(theQueriedServices, theSelectedPort);
    let result: any = {
      'selectedPort': theSelectedPort,
    }
    if (this.dataPortList.indexOf(theSelectedPort) > -1)
    {
      //
      // Data Ports 0, 1, 2 and 3
      //
      const selectedSimpleDiagnostics = this.getSimpleDiagnosticsFromSelectedPort(theONTDiagnostics, theSelectedPort);
      // this.fdaLog.debug('portDiagnosticsFromServicesPortAndDiagnostics selectedSimpleDiagnostics', selectedSimpleDiagnostics); 
      const selectedEthPortDiagnostics = this.getEthPortDiagnosticsFromSelectedPort(theONTDiagnostics, theSelectedPort);
      // this.fdaLog.debug('portDiagnosticsFromServicesPortAndDiagnostics selectedEthPortDiagnostics', selectedEthPortDiagnostics); 

      result.portType = 'Data';
      result.portTitle = result.portType + ' Port ' + theSelectedPort.substring(1),
      result.serviceExists = selectedServiceAndCircuit != null;
      result.serviceActive = (selectedServiceAndCircuit && selectedServiceAndCircuit.service.serviceActive == 'Yes');
      result.portProvisioned = (selectedServiceAndCircuit && selectedServiceAndCircuit.service.serviceActive == 'Yes') ? 'Port provisioned' : 'Not provisioned';
      result.deviceDetected = (selectedSimpleDiagnostics && selectedSimpleDiagnostics.eth_port_status == 'In Service') ? 'Device detected' : 'No device';
      result.portState  = this.parsePortState( selectedEthPortDiagnostics.eth_port_current_port_state);
      result.portSpeed  = this.parsePortSpeed( selectedEthPortDiagnostics.eth_port_speed);
      result.portDuplex = this.parsePortDuplex(selectedEthPortDiagnostics.eth_port_duplex);

      let portSpeedDuplexArray = [];
      if (result.portSpeed) {
        portSpeedDuplexArray.push(result.portSpeed);
      }
      if (result.portDuplex) {
        portSpeedDuplexArray.push(result.portDuplex);
      }
      result.portSpeedDuplex = portSpeedDuplexArray.join(' / ');
      
      if (selectedEthPortDiagnostics.eth_port_performance_monitoring 
        && selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min']
        && selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min'].length > 0)
      {
        const fifteenUpCount: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min'][0].upstream_octets.count;
        const fifteenDownCount: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min'][0].downstream_octets.count;
        const fifteenUpRate: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min'][0].upstream_octets.rate_per_second;
        const fifteenDownRate: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min'][0].downstream_octets.rate_per_second;
        result.fifteenMinStartedAt = moment(
          selectedEthPortDiagnostics.eth_port_performance_monitoring['15-min'][0].started_at,
          'YYYY/MM/DD HH:mm:ss'
        );
        // console.log('portDiagnosticsFromServicesPortAndDiagnostics COMPARE', fifteenUpRate, this.env.low_traffic_rate_threshold, (fifteenUpRate > this.env.low_traffic_rate_threshold ? 'true' : 'false'));
        // console.log('portDiagnosticsFromServicesPortAndDiagnostics COMPARE', fifteenDownRate, this.env.low_traffic_rate_threshold, (fifteenDownRate > this.env.low_traffic_rate_threshold ? 'true' : 'false'));
        result.fifteenMinUpstreamBytes    = this.octetsToBytes(fifteenUpCount);
        result.fifteenMinDownstreamBytes  = this.octetsToBytes(fifteenDownCount);
        result.fifteenMinUpstreamRate     = this.octetRateToBitRate(fifteenUpRate);
        result.fifteenMinDownstreamRate   = this.octetRateToBitRate(fifteenDownRate);
        result.fifteenMinUpstreamRateOK   = ((fifteenUpRate * 8) > this.env.low_traffic_rate_threshold);
        result.fifteenMinDownstreamRateOK = ((fifteenDownRate * 8) > this.env.low_traffic_rate_threshold);
      }

      if (selectedEthPortDiagnostics.eth_port_performance_monitoring
        && selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day']
        && selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'].length > 0)
      {
        const oneDayUpCount: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].upstream_octets.count;
        const oneDayDownCount: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].downstream_octets.count;
        const oneDayUpRate: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].upstream_octets.rate_per_second;
        const oneDayDownRate: number = selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].downstream_octets.rate_per_second;
        result.oneDayStartedAt = moment(
          selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].started_at,
          'YYYY/MM/DD HH:mm:ss'
        );
        result.oneDayUpstreamBytes    = this.octetsToBytes(selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].upstream_octets.count);
        result.oneDayDownstreamBytes  = this.octetsToBytes(selectedEthPortDiagnostics.eth_port_performance_monitoring['1-day'][0].downstream_octets.count);
        result.oneDayUpstreamRate     = this.octetRateToBitRate(oneDayUpRate);
        result.oneDayDownstreamRate   = this.octetRateToBitRate(oneDayDownRate);
        result.oneDayUpstreamRateOK   = ((oneDayUpRate * 8) > this.env.low_traffic_rate_threshold);
        result.oneDayDownstreamRateOK = ((oneDayDownRate * 8) > this.env.low_traffic_rate_threshold);
      }
      result.pendingChanges = selectedServiceAndCircuit ? this.parseServiceStatus(selectedServiceAndCircuit.service.serviceStatus) : '';
      // result.lastModified   = selectedServiceAndCircuit ? this.parseDateModified(selectedServiceAndCircuit.service.dateModified) : '';
      result.portDiagnosticStatus = this.dataPortStatusFromServiceAndDiagnostics(selectedServiceAndCircuit, selectedSimpleDiagnostics, selectedEthPortDiagnostics);


      // // Debug
      // result.selectedSimpleDiagnostics = selectedSimpleDiagnostics;
      // result.selectedEthPortDiagnostics = selectedEthPortDiagnostics;
      // result.selectedServiceAndCircuit = selectedServiceAndCircuit;
      // result.theONTDiagnostics = theONTDiagnostics;
      // // End Debug
    }
    else if (this.voicePortList.indexOf(theSelectedPort) > -1)
    {
      //
      // Pots Ports 0 and 1
      //
      const selectedPotsPortDiagnostics = this.getPotsPortDiagnosticsFromSelectedPort(theONTDiagnostics, theSelectedPort);

      result.portType = 'Voice';
      result.portTitle = result.portType + ' Port ' + theSelectedPort.substring(1),
      result.serviceExists = selectedServiceAndCircuit != null;
      result.serviceActive = (selectedServiceAndCircuit && selectedServiceAndCircuit.service.serviceActive == 'Yes');
      result.portProvisioned = (selectedServiceAndCircuit && selectedServiceAndCircuit.service.serviceActive == 'Yes') ? 'Port provisioned' : 'Not provisioned';

      result.pendingChanges = selectedServiceAndCircuit ? this.parseServiceStatus(selectedServiceAndCircuit.service.serviceStatus) : '';
      // result.lastModified   = selectedServiceAndCircuit ? this.parseDateModified(selectedServiceAndCircuit.service.dateModified) : '';
      result.portDiagnosticStatus = this.voicePortStatusFromServiceAndDiagnostics(selectedServiceAndCircuit, selectedPotsPortDiagnostics);

      result.selectedPotsPortDiagnostics = selectedPotsPortDiagnostics;
    }
    return result;
  }

  private parsePortState(portState: string) : string
  {
    if (!portState)
    {
      return '';
    }
    let result = portState;
    if (portState.toLowerCase() == 'up 1g full-duplex')
    {
      result = '1G / Full Duplex';
    }
    return result;
  }

  private parsePortSpeed(portSpeed)
  {
    if (!portSpeed)
    {
      return '';
    }
    let result = portSpeed;
    if (portSpeed.toLowerCase() == 'auto')
    {
      result = 'AUTO';
    }
    return result;
  }

  private parsePortDuplex(portDuplex)
  {
    if (!portDuplex)
    {
      return '';
    }
    let result = portDuplex;
    if (portDuplex.toLowerCase() == 'full-duplex')
    {
      result = 'Full Duplex';
    }
    return result;
  }

  private octetsToBytes(octets)
  {
    let result = octets;
    if (octets > Math.pow(2, 40))
    {
      result = Math.round(octets / Math.pow(2, 40)) + ' TB';
    }
    else if (octets > Math.pow(2, 30))
    {
      result = Math.round(octets / Math.pow(2, 30)) + ' GB';
    }
    else if (octets > Math.pow(2, 20))
    {
      result = Math.round(octets / Math.pow(2, 20)) + ' MB';
    }
    else if (octets > Math.pow(2, 10))
    {
      result = Math.round(octets / Math.pow(2, 10)) + ' kB';
    }
    else
    {
      result = octets + ' bytes';
    }
    return result;
  }

  private octetRateToBitRate(octetRate: number)
  {
    let bitRate: number = octetRate * 8;
    let result: string = '';
    if (bitRate > Math.pow(2, 40))
    {
      result = Math.round(bitRate / Math.pow(2, 40)) + ' Tbit';
    }
    else if (bitRate > Math.pow(2, 30))
    {
      result = Math.round(bitRate / Math.pow(2, 30)) + ' Gbit';
    }
    else if (bitRate > Math.pow(2, 20))
    {
      result = Math.round(bitRate / Math.pow(2, 20)) + ' Mbit';
    }
    else if (bitRate > Math.pow(2, 10))
    {
      result = Math.round(bitRate / Math.pow(2, 10)) + ' kbit';
    }
    else
    {
      result = Math.round(bitRate) + ' bit';
    }
    result += '/s';
    return result;
  }

  private parseServiceStatus(status)
  {
    let result = status;
    if (status == 'Active')
    {
      result = 'No pending changes';
    }
    else if (status == 'Pending Superseded')
    {
      result = 'Changes pending';
    }
    return result;
  }

  private parseDateModified(iso8601Date)
  {
    const dateMoment = moment(iso8601Date);
    return 'Service last modified ' + dateMoment.format('D MMMM YYYY');
  }

  private portDetailsFromServiceAndPort(theQueriedServices, theSelectedPort)
  {
    console.log('portDetailsFromServiceAndPort', {theSelectedPort});
    const selectedServiceAndCircuit = this.getServiceAndCircuitFromSelectedPort(theQueriedServices, theSelectedPort);
    const voiceOrDataPort = theSelectedPort.substring(0, 1) == 'd' ? 'Data Port ' : 'Voice Port ';
    return {
      'combinedSelectedPort': theSelectedPort,
      'portTitle':            voiceOrDataPort + theSelectedPort.substring(1),
      'serviceExists':        selectedServiceAndCircuit != null,
      'brand':                selectedServiceAndCircuit ? selectedServiceAndCircuit.service.brand : '',
      'package':              selectedServiceAndCircuit ? selectedServiceAndCircuit.service.serviceDescription : '',
      'serviceType':          selectedServiceAndCircuit ? selectedServiceAndCircuit.service.productFamilyGroup : '',
      'serviceStatus':        selectedServiceAndCircuit ? selectedServiceAndCircuit.service.serviceStatus : '',
      'fibreServiceId':       selectedServiceAndCircuit ? selectedServiceAndCircuit.service.serviceIdentifier : '',
      'fibreCircuitId':       selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.circuitIdentifier : '',
      'fibreCircuitType':     selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.circuitType : '',
      'remoteId':             selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.remoteID : '',
      'lanPort':              selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.terminalPort : '',
      'UNITaggingMode':       selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.UNITagging : '',
      'handoverLinkId':       selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.ENNI : '',
      'SVLAN':                selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.SVID : '',
      'CVLAN':                selectedServiceAndCircuit ? selectedServiceAndCircuit.circuit.CVID : '',
      'lastModified':         selectedServiceAndCircuit && selectedServiceAndCircuit.service.dateModified ?  moment(selectedServiceAndCircuit.service.dateModified) : null,
      'selectedServiceAndCircuit': selectedServiceAndCircuit,
    };
  }

  private isNoData(resultsArray)
  {
    let result = false;
    for (let i = 0; i < resultsArray.length; i++)
    {
      if (resultsArray[i] == 'NoData')
      {
        result = true;
        break;
      }
    }
    return result;
  }


}







